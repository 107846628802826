define("ember-functions-as-helper-polyfill/initializers/install-function-helper-manager", ["exports", "@ember/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.FunctionHelperManager = void 0;
  _exports.initialize = initialize;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } /* eslint-disable @typescript-eslint/no-explicit-any */ /* eslint-disable @typescript-eslint/ban-types */ // typed-ember doesn't have types for `@ember/helper` yet
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  function initialize( /* appInstance */
  ) {
    // noop.
    // we just want the host app to import this file
    // (because the default manager behavior is in glimmer-vm,
    //  and I don't know how to monkey-patch ESM... (I don't think I'd want to either? (danger!)))
  }
  var _default = _exports.default = {
    initialize
  };
  class FunctionHelperManager {
    constructor() {
      _defineProperty(this, "capabilities", (0, _helper.capabilities)('3.23', {
        hasValue: true,
        hasDestroyable: false,
        hasScheduledEffect: false
      }));
    }
    createHelper(fn, args) {
      return {
        fn,
        args
      };
    }
    getValue({
      fn,
      args
    }) {
      if (Object.keys(args.named).length > 0) {
        let argsForFn = [...args.positional, args.named];
        return fn(...argsForFn);
      }
      return fn(...args.positional);
    }
    getDebugName(fn) {
      if (fn.name) {
        return `(helper function ${fn.name})`;
      }
      return '(anonymous helper function)';
    }
  }
  _exports.FunctionHelperManager = FunctionHelperManager;
  const FUNCTIONAL_HELPER_MANAGER = new FunctionHelperManager();
  (0, _helper.setHelperManager)(() => FUNCTIONAL_HELPER_MANAGER, Function.prototype);
});