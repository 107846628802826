define("ember-svg-jar/inlined/focus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M37.008 18.816h2.496c.384 0 .72-.336.72-.72a.738.738 0 00-.72-.72H36.96c-.288-5.712-4.896-10.32-10.656-10.656V4.176a.738.738 0 00-.72-.72.738.738 0 00-.72.72V6.72c-5.712.336-10.32 4.944-10.704 10.656h-2.544a.738.738 0 00-.72.72c0 .384.336.72.72.72h2.496c.336 5.76 4.944 10.368 10.704 10.704v2.496c0 .384.336.72.72.72.384 0 .72-.336.72-.72V29.52c5.808-.336 10.416-4.944 10.752-10.704zM26.304 28.08v-2.448a.738.738 0 00-.72-.72.738.738 0 00-.72.72v2.448c-4.944-.336-8.88-4.32-9.264-9.264h2.448c.384 0 .72-.336.72-.72a.738.738 0 00-.72-.72H15.6c.336-4.944 4.32-8.88 9.216-9.216v2.448c0 .384.336.72.72.72.384 0 .72-.336.72-.72V8.16c4.944.336 8.88 4.32 9.216 9.216H33.12a.738.738 0 00-.72.72c0 .384.336.72.72.72h2.448c-.336 4.944-4.32 8.928-9.264 9.264zm-.72-13.92a3.954 3.954 0 00-3.936 3.936 3.954 3.954 0 003.936 3.936 3.954 3.954 0 003.936-3.936 3.923 3.923 0 00-3.936-3.936zm0 6.432a2.479 2.479 0 01-2.496-2.496 2.479 2.479 0 012.496-2.496 2.479 2.479 0 012.496 2.496 2.479 2.479 0 01-2.496 2.496zm15.264 9.36c-2.592 3.504-3.552 7.92-2.64 12.096l.624 2.784a.716.716 0 11-1.392.336l-.624-2.784a16.312 16.312 0 012.88-13.296c2.4-3.216 3.456-6.288 3.408-9.936 0-9.648-7.872-17.52-17.52-17.52S8.112 9.552 8.112 19.2c0 2.832-1.296 4.032-2.256 4.944-.624.624-.96.96-.96 1.488 0 1.2 1.296 2.496 2.496 2.496.384 0 .72.336.72.72v4.272c0 .96.192 1.632.576 2.016.672.672 1.92.672 3.264.672h1.872c1.248 0 2.448.48 3.36 1.296a5.508 5.508 0 011.776 3.312l.912 6.528c.048.384-.24.768-.624.816h-.096a.721.721 0 01-.72-.624l-.912-6.528c-.144-.96-.576-1.824-1.296-2.448-.672-.624-1.536-.912-2.4-.912h-1.872c-1.536 0-3.168 0-4.272-1.056-.672-.672-1.008-1.68-1.008-3.024V29.52c-1.728-.384-3.216-2.064-3.216-3.84 0-1.2.72-1.872 1.44-2.544.864-.816 1.776-1.68 1.776-3.888 0-10.464 8.496-18.96 18.96-18.96S44.544 8.736 44.544 19.2c.048 3.888-1.152 7.344-3.696 10.752z\" fill=\"#5A5F68\"/>",
    "attrs": {
      "width": "48",
      "height": "48",
      "viewBox": "0 0 48 48",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});