define("ember-svg-jar/inlined/whats-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3.5 20.5A1.5 1.5 0 005 22h6.5V10.5h-8v10zM12.5 22H19a1.5 1.5 0 001.5-1.5v-10h-8V22zM3 6a1 1 0 00-1 1v1.5a1 1 0 001 1h8.5V6H3zM21 6h-8.5v3.5H21a1 1 0 001-1V7a1 1 0 00-1-1zM13.175 5c.775-1.455 1.645-2 2.325-2a1 1 0 01.215 0 1 1 0 01.785 1.05 1.435 1.435 0 01-.54.95h1.27c.152-.273.244-.574.27-.885a2 2 0 00-1.61-2.075c-1-.195-2.295.325-3.385 2.125-.165.27-.315.549-.45.835h1.12zM8.115 2.04A2 2 0 006.5 4.115c.025.31.117.612.27.885h1.27a1.435 1.435 0 01-.54-.95A1 1 0 018.31 3a1 1 0 01.215 0c.655 0 1.525.545 2.3 2h1.12a9.72 9.72 0 00-.445-.83c-1.09-1.805-2.385-2.325-3.385-2.13z\" fill=\"#74747E\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});