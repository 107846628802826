define("ember-svg-jar/inlined/stacked", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<mask id=\"mask0_3187_31567\" style=\"mask-type:alpha\" maskUnits=\"userSpaceOnUse\" x=\"0\" y=\"0\" width=\"50\" height=\"50\"><rect width=\"49.882\" height=\"49.882\" rx=\"2\" fill=\"#ECE9F1\"/></mask><g mask=\"url(#mask0_3187_31567)\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" fill=\"currentColor\"><path opacity=\".3\" d=\"M14.708 3.765a.493.493 0 00-.492.492v40.427c0 .272.22.492.492.492h6.432c.272 0 .493-.22.493-.492V4.257a.493.493 0 00-.493-.492h-6.432zM.493 9.945a.493.493 0 00-.493.493v34.246c0 .272.22.492.493.492h6.431c.273 0 .493-.22.493-.492V10.438a.493.493 0 00-.492-.493H.492zm27.94.493c0-.272.22-.493.492-.493h6.432c.272 0 .493.221.493.493v34.246c0 .272-.22.492-.493.492h-6.432a.493.493 0 01-.492-.492V10.438zm14.708 6.615a.493.493 0 00-.493.493v27.138c0 .272.221.492.493.492h6.432c.272 0 .492-.22.492-.492V17.546a.493.493 0 00-.492-.493H43.14z\"/><path d=\"M.493 22.307a.493.493 0 00-.493.492v21.885c0 .272.22.492.493.492h6.431c.273 0 .493-.22.493-.492V22.799a.493.493 0 00-.492-.492H.492zm14.215 5.254a.493.493 0 00-.492.492v16.63c0 .273.22.493.492.493h6.432c.272 0 .493-.22.493-.492v-16.63a.493.493 0 00-.493-.493h-6.432zm13.725 11.927c0-.272.22-.493.492-.493h6.432c.272 0 .493.22.493.493v5.195c0 .273-.22.493-.493.493h-6.432a.493.493 0 01-.492-.493v-5.195zm14.708-6.055a.493.493 0 00-.493.492v10.759c0 .272.221.492.493.492h6.432c.272 0 .492-.22.492-.492V33.925a.493.493 0 00-.492-.492H43.14z\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});