define("ember-cli-is-component/services/is-component", ["exports", "@ember/service", "@ember/application"], function (_exports, _service, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class IsComponentService extends _service.default {
    test(name) {
      name = (name || '').trim();
      if (!name) {
        return false;
      }
      const owner = (0, _application.getOwner)(this);
      const lookup = owner.lookup('component-lookup:main');
      if (!lookup.componentFor) {
        return !!lookup.lookupFactory(name);
      }
      return !!(lookup.componentFor(name, owner) || lookup.layoutFor(name, owner));
    }
  }
  _exports.default = IsComponentService;
});