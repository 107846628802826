define("ember-svg-jar/inlined/truscore_logo-no-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7.952 4.941c-1.043 0-1.887.914-1.887 2.039v16.678H9.84V6.98C9.834 5.855 8.99 4.94 7.952 4.94zM27.252 10.687c0-1.125-.843-2.039-1.887-2.039-1.043 0-1.886.914-1.886 2.039v12.975h3.773V10.687zM13.753 0c-1.043 0-1.887.914-1.887 2.038v21.625h3.774V2.038C15.64.908 14.796 0 13.753 0zM21.445 4.2c0-1.124-.843-2.038-1.886-2.038-1.044 0-1.887.914-1.887 2.038v19.463h3.773V4.2zM.259 30.892v-9.813c0-1.124.843-2.038 1.887-2.038 1.043 0 1.886.914 1.886 2.038v9.813c0 .037-.016.07-.021.108H.28c-.006-.038-.022-.07-.022-.108z\" fill=\"#74A900\"/>",
    "attrs": {
      "width": "28",
      "height": "31",
      "viewBox": "0 0 28 31",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});