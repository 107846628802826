define("ember-svg-jar/inlined/software", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8.92 7.154c-1.515 0-2.715 1.293-2.715 2.83v11.833h-1.48a2.627 2.627 0 00-2.615 2.621v10.786a2.628 2.628 0 002.616 2.622h6.452a2.628 2.628 0 002.616-2.622v-3.042h6.03v4.256h-2.195a.704.704 0 000 1.408h13.832a.704.704 0 100-1.408h-2.196v-4.256H40.17c1.515 0 2.72-1.297 2.72-2.833V9.983c0-1.536-1.205-2.829-2.72-2.829H8.92zm0 1.408h31.25c.718 0 1.313.6 1.313 1.421v15.473h-27.69V24.44a2.628 2.628 0 00-2.615-2.622H7.606V9.983c0-.82.596-1.421 1.314-1.421zM4.726 23.219h6.452c.682 0 1.208.532 1.208 1.22v10.785c0 .687-.526 1.214-1.208 1.214H4.726c-.683 0-1.21-.526-1.21-1.214V24.44c0-.688.527-1.22 1.21-1.22zm9.068 3.643h27.69v2.488c0 .82-.596 1.426-1.314 1.426H13.794v-3.914zm7.437 5.32h6.628v4.256H21.23v-4.256zm-13.28 1.425a.9.9 0 100 1.798.9.9 0 000-1.798z\" fill=\"#5A5F68\"/>",
    "attrs": {
      "width": "45",
      "height": "45",
      "viewBox": "0 0 45 45",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});