define("ember-svg-jar/inlined/CMS", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"currentColor\"><path d=\"M1.046 18.818c-.297 0-.545-.1-.745-.3-.2-.201-.301-.45-.301-.745s.1-.545.3-.745c.2-.2.45-.3.746-.3h20.909c.296 0 .544.1.744.3.2.2.301.448.301.745s-.1.544-.3.744c-.2.2-.45.301-.745.301zm2.09-3.136a2.013 2.013 0 01-1.476-.615 2.013 2.013 0 01-.614-1.476V2.09c0-.575.204-1.067.614-1.477C2.07.204 2.56 0 3.136 0h16.727c.575 0 1.068.205 1.477.614.41.41.614.902.614 1.477v11.5c0 .575-.204 1.067-.614 1.476-.41.41-.902.615-1.477.615zm0-2.091h16.727V2.09H3.136z\"/><path d=\"M17.773 5.227h-5.227v1.046h5.227zM17.773 7.318h-5.227v1.046h5.227zM15.682 9.41h-3.136v1.045h3.136zM7.84 8.168c.71 0 1.338.17 1.848.392.47.209.767.68.767 1.189v.705H5.227v-.7c0-.515.297-.985.767-1.19a4.549 4.549 0 011.847-.396zm0-2.94c.724 0 1.308.583 1.308 1.306S8.564 7.841 7.84 7.841s-1.307-.584-1.307-1.307.584-1.307 1.307-1.307z\"/></g>",
    "attrs": {
      "width": "23",
      "height": "18.818",
      "fill": "none",
      "viewBox": "0 0 23 18.818",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});