define("ember-svg-jar/inlined/live_connect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11.591.54h1.385v4.633c2.4.004 4.797-.007 7.195 0 .472-.024 1.119.113 1.11.708.087 2.93.002 5.867.041 8.8.01.519-.007 1.336-.713 1.343-2.541.088-5.09.007-7.633.035v4.4H11.54c-3.53-.64-7.069-1.231-10.603-1.852C.934 13.203.934 7.799.934 2.397 4.487 1.776 8.041 1.173 11.591.54zm1.385 5.327v2.56c.906.845 1.8 1.705 2.72 2.536 1.528-1.434 3.053-2.87 4.586-4.299.255-.19.347-.488.391-.792-2.566-.011-5.13-.002-7.697-.005zm3.447 5.302c-.257.213-.512.54-.89.47-.917-.697-1.69-1.568-2.554-2.332-.005 2.018-.003 4.037-.003 6.057h7.644c-.002-2.715.005-5.431-.002-8.146-1.4 1.315-2.803 2.629-4.195 3.951zM5.9 6.715c-2.223.795-2.626 3.778-1.797 5.717.63 1.681 2.948 2.355 4.331 1.172 1.501-1.314 1.608-3.69.853-5.427-.554-1.263-2.08-1.955-3.387-1.462zm.225 1.392c.616-.294 1.387.005 1.677.614.463.917.46 2.036.157 3.004-.215.688-.968 1.256-1.697.985-.91-.288-1.186-1.367-1.196-2.212-.011-.876.19-1.967 1.059-2.39z\"/>",
    "attrs": {
      "viewBox": "0 0 23 23",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});