define("ember-query-params-service/index", ["exports", "ember-query-params-service/services/query-params", "ember-query-params-service/utils/query-param"], function (_exports, _queryParams, _queryParam) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "QueryParamsService", {
    enumerable: true,
    get: function () {
      return _queryParams.default;
    }
  });
  Object.defineProperty(_exports, "queryParam", {
    enumerable: true,
    get: function () {
      return _queryParam.queryParam;
    }
  });
});