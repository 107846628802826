define("ember-svg-jar/inlined/pie", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<mask id=\"mask0_3187_31556\" style=\"mask-type:alpha\" maskUnits=\"userSpaceOnUse\" x=\"0\" y=\"0\" width=\"50\" height=\"50\"><rect width=\"49.882\" height=\"49.882\" rx=\"2\" fill=\"#ECE9F1\"/></mask><g mask=\"url(#mask0_3187_31556)\"><mask id=\"mask1_3187_31556\" style=\"mask-type:alpha\" maskUnits=\"userSpaceOnUse\" x=\"0\" y=\"0\" width=\"51\" height=\"51\"><circle cx=\"25.098\" cy=\"25.098\" r=\"25.098\" fill=\"#C4C4C4\"/></mask><g mask=\"url(#mask1_3187_31556)\" fill=\"currentColor\"><path d=\"M25.098 0v50.196C11.237 50.196 0 38.96 0 25.098 0 11.237 11.237 0 25.098 0z\"/><path d=\"M25.099 53.647V24.471l20.705 20.235-8.94 7.059-11.765 1.882z\" fill-opacity=\".3\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M43.36 42.316a25.01 25.01 0 006.837-17.218C50.197 11.237 38.96 0 25.099 0v24.47l18.26 17.846z\" fill-opacity=\".7\"/></g></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});