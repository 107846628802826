define("ember-svg-jar/inlined/education", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M92 5.8h-5.3C84.8 2.3 73 1 66.5 1 60.6 1 50.2 2.1 47 4.9c-3.3-2.7-13.6-3.5-19.5-3.5-6.5 0-18.3.9-20.2 4.4H2c-.6 0-1 .4-1 1v66.8c0 .6.4 1 1 1h90c.6 0 1-.4 1-1V6.8c0-.5-.4-1-1-1zM67 3c3.9 0 7.2.3 9.8.8V44l-4.2-3.9c-.4-.4-1-.4-1.4 0L67 44V3zm-2 0v11h-9.8c-.6 0-1 .4-1 1s.4 1 1 1H65v30.3c0 .4.2.8.6.9.1.1.3.1.4.1.2 0 .5-.1.7-.3l5.2-4.8 5.2 4.8c.3.3.7.3 1.1.2.4-.2.6-.5.6-.9V4.2c4.1.9 6.2 2.1 6.2 2.6V65c-4-2.2-13.1-2.9-18.5-2.9S52 62.8 48 65V6.8c0-1 5.8-3.6 17-3.8zm-37.5.4C39.7 3.4 46 5.8 46 6.8V65c-4-2.2-13.1-2.9-18.5-2.9S13 62.8 9 65V6.8c0-1 6.3-3.4 18.5-3.4zM3 7.8h4v59.7c0 .6.4 1 1 1s1-.4 1-1c0-1 6.3-3.4 18.5-3.4S46 66.5 46 67.5v5H3V7.8zm88 64.8H48v-5c0-1 6.3-3.4 18.5-3.4S85 66.5 85 67.5c0 .6.4 1 1 1s1-.4 1-1V7.8h4v64.8z\" fill=\"#74747E\" stroke=\"#74747E\"/><path d=\"M16.2 16h22.6c.6 0 1-.4 1-1s-.4-1-1-1H16.2c-.6 0-1 .4-1 1s.5 1 1 1zM16.2 26.9h22.6c.6 0 1-.4 1-1s-.4-1-1-1H16.2c-.6 0-1 .4-1 1s.5 1 1 1zM16.2 37.8h22.6c.6 0 1-.4 1-1s-.4-1-1-1H16.2c-.6 0-1 .4-1 1s.5 1 1 1zM16.2 48.7h14.4c.6 0 1-.4 1-1s-.4-1-1-1H16.2c-.6 0-1 .4-1 1s.5 1 1 1zM55.2 26.9H60c.6 0 1-.4 1-1s-.4-1-1-1h-4.8c-.6 0-1 .4-1 1s.4 1 1 1z\" fill=\"#74747E\" stroke=\"#74747E\"/>",
    "attrs": {
      "width": "94",
      "height": "76",
      "viewBox": "0 0 94 76",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});