define("ember-svg-jar/inlined/quality", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0_11736_310383)\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6.542 31.428v15.045a.943.943 0 001.37.84l10.82-5.492 10.951 5.538a.942.942 0 001.368-.841v-15.09l2.692-1.504a3.65 3.65 0 001.87-3.238l-.065-4.596 1.633-4.297a3.648 3.648 0 00-.65-3.683l-3.004-3.48-1.51-4.34a3.65 3.65 0 00-2.865-2.404l-4.538-.734L20.666.798a3.652 3.652 0 00-3.739 0l-3.948 2.354-4.538.734A3.651 3.651 0 005.576 6.29l-1.51 4.34-3.004 3.48a3.651 3.651 0 00-.65 3.683l1.633 4.297-.065 4.596a3.653 3.653 0 001.87 3.238l2.691 1.504h.001zm1.886 1.43v12.08l9.876-5.015a.942.942 0 01.852 0l10.01 5.061V32.86l-2.34 2.87a3.65 3.65 0 01-3.514 1.278l-4.515-.861-4.515.861a3.652 3.652 0 01-3.514-1.278l-2.34-2.87zm9.464-30.44a1.764 1.764 0 011.809 0l4.058 2.42a1.229 1.229 0 00.427.154l4.665.756a1.765 1.765 0 011.384 1.162l1.554 4.462a1.214 1.214 0 00.227.393l3.088 3.577a1.761 1.761 0 01.314 1.781L33.74 21.54a1.173 1.173 0 00-.08.448l.067 4.724a1.766 1.766 0 01-.904 1.566l-4.125 2.305a1.18 1.18 0 00-.347.292l-2.987 3.663a1.764 1.764 0 01-1.7.618l-4.64-.886a1.197 1.197 0 00-.455 0l-4.641.886a1.762 1.762 0 01-1.7-.618l-2.985-3.663a1.23 1.23 0 00-.348-.292L4.77 28.278a1.76 1.76 0 01-.905-1.566l.067-4.724a1.219 1.219 0 00-.079-.448l-1.678-4.417a1.766 1.766 0 01.314-1.78l3.088-3.578a1.192 1.192 0 00.227-.393L7.357 6.91a1.77 1.77 0 011.385-1.162l4.665-.756a1.227 1.227 0 00.426-.155l4.059-2.42zm.905 2.604c-7.537 0-13.656 6.119-13.656 13.656 0 7.537 6.119 13.656 13.656 13.656 7.536 0 13.655-6.12 13.655-13.656 0-7.537-6.119-13.656-13.655-13.656zm0 1.886c6.496 0 11.77 5.273 11.77 11.77 0 6.496-5.274 11.77-11.77 11.77-6.497 0-11.77-5.274-11.77-11.77 0-6.497 5.273-11.77 11.77-11.77zM10.948 18.86l5.111 5.11a.942.942 0 001.333 0l9.253-9.252a.943.943 0 00-1.334-1.333l-8.585 8.586-4.445-4.444a.942.942 0 10-1.333 1.333z\" fill=\"#5A5F68\"/></g><defs><clipPath id=\"clip0_11736_310383\"><path fill=\"#fff\" d=\"M0 0h37.895v48H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "38",
      "height": "48",
      "viewBox": "0 0 38 48",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});