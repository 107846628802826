define("ember-svg-jar/inlined/horizontalbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<mask id=\"mask0_3187_31584\" style=\"mask-type:alpha\" maskUnits=\"userSpaceOnUse\" x=\"0\" y=\"0\" width=\"50\" height=\"50\"><rect width=\"49.882\" height=\"49.882\" rx=\"2\" fill=\"#ECE9F1\"/></mask><g mask=\"url(#mask0_3187_31584)\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M.494 0A.494.494 0 000 .494v6.449c0 .272.221.493.494.493h34.335a.494.494 0 00.494-.493V.493A.494.494 0 0034.83 0H.494zm0 14.253a.494.494 0 00-.494.494v6.449c0 .273.221.494.494.494h16.674a.494.494 0 00.494-.494v-6.449a.494.494 0 00-.494-.494H.494zM0 29c0-.273.221-.494.494-.494h34.335c.273 0 .494.221.494.494v6.449a.494.494 0 01-.494.494H.494A.494.494 0 010 35.449V29zm.494 13.76a.494.494 0 00-.494.494v6.449c0 .272.221.493.494.493h47.35a.494.494 0 00.493-.493v-6.45a.494.494 0 00-.494-.493H.493z\" fill=\"currentColor\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});