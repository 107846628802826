define("ember-svg-jar/inlined/nylas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<mask id=\"a\" width=\"48\" height=\"50\" x=\"0\" y=\"0\" maskUnits=\"userSpaceOnUse\" style=\"mask-type:alpha\"><path fill=\"#fff\" d=\"M0 .7h47.3v49H0V.7z\"/></mask><g mask=\"url(#a)\"><path fill=\"#00E4BF\" fill-rule=\"evenodd\" d=\"M0 26l22 13.6v10.1L0 36V26zM25.4.6l22 13.7v10.1l-22-13.7V.7zm0 13.7l22 13.7v10l-22-13.6v-10zM41.7 48L25.4 38V27.7l22 13.7V45a3.7 3.7 0 01-5.7 3.1zM5.5 2L22 12v10L0 8.6V4.9a3.6 3.6 0 015.5-3zM0 12.3L22 26v10L0 22.5V12.3z\" clip-rule=\"evenodd\"/></g><path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M66.5 14.5h3l8.7 14.9 2.5 5h.2c-.2-2.5-.4-5.2-.4-7.7V14.5h2.9v23.7h-3.1l-8.7-15-2.5-4.8h-.2c.2 2.4.4 4.9.4 7.4v12.4h-2.8V14.5zm20.9 30.9l.6-2.3 1.3.2c2 0 3.3-1.6 4-3.7l.4-1.3-7-17.7h3l3.6 9.8 1.7 5h.2l1.5-5 3.1-9.8h2.9l-6.6 19c-1.3 3.6-3 6.2-6.6 6.2-.8 0-1.5-.1-2-.4zm18.3-10.7V14.5h3v20.4c0 1 .4 1.3.8 1.3h.7l.4 2.2a4 4 0 01-1.7.3c-2.2 0-3.2-1.5-3.2-4zm18.2-.7v-5c-5.9.7-7.9 2.2-7.9 4.4 0 2 1.4 2.9 3.1 2.9 1.7 0 3-.9 4.8-2.4zm-10.8-.3c0-3.9 3.3-5.8 10.8-6.6 0-2.3-.7-4.5-3.6-4.5-2 0-3.9 1-5.3 2l-1.1-2.1c1.6-1 4-2.3 6.9-2.3 4.3 0 6.1 2.8 6.1 7.2v10.8h-2.5l-.2-2h-.1a9.4 9.4 0 01-5.9 2.5c-2.9 0-5.1-1.9-5.1-5zm16.3 2.5l1.5-2a8 8 0 005.1 2.1c2.4 0 3.5-1.2 3.5-2.7 0-1.8-2-2.6-4-3.3-2.5-1-5.2-2.1-5.2-5.1 0-2.8 2.3-5 6.1-5 2.2 0 4.1.9 5.5 2l-1.4 1.9a6.5 6.5 0 00-4-1.6c-2.3 0-3.3 1.2-3.3 2.5 0 1.7 1.8 2.3 3.8 3 2.6 1 5.4 2 5.4 5.4 0 2.9-2.3 5.3-6.5 5.3-2.5 0-4.9-1-6.5-2.5z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 143 50"
    }
  };
});