define("ember-svg-jar/inlined/standard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><rect width=\"139.3\" height=\"107.1\" fill=\"#EEF1F9\" rx=\"2\"/><rect width=\"37\" height=\"107.1\" fill=\"#5e1de1\" rx=\"2\"/><rect width=\"80.2\" height=\"28.3\" x=\"47.5\" y=\"11.4\" fill=\"#B9C8D9\" rx=\"4\"/><rect width=\"46.1\" height=\"6\" x=\"47.5\" y=\"49\" fill=\"#B9C8D9\" rx=\"3\"/><rect width=\"46.1\" height=\"6\" x=\"47.5\" y=\"59.1\" fill=\"#B9C8D9\" rx=\"3\"/><rect width=\"46.1\" height=\"6\" x=\"47.5\" y=\"69.1\" fill=\"#B9C8D9\" rx=\"3\"/><path stroke=\"#FFF\" stroke-linecap=\"round\" stroke-width=\"3\" d=\"M18.567 11.888h10m-10 12.485h10m-10 12.485h10m-10 12.485h10\"/><circle cx=\"10.1\" cy=\"12.1\" r=\"3.4\" stroke=\"#FFF\" stroke-width=\"3\"/><circle cx=\"10.1\" cy=\"24.1\" r=\"3.4\" stroke=\"#FFF\" stroke-width=\"3\"/><circle cx=\"10.1\" cy=\"36.1\" r=\"3.4\" stroke=\"#FFF\" stroke-width=\"3\"/><circle cx=\"10.1\" cy=\"48.1\" r=\"3.4\" stroke=\"#FFF\" stroke-width=\"3\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 140 108"
    }
  };
});