define("ember-svg-jar/inlined/transportation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<mask id=\"path-1-inside-1_11574_304076\" fill=\"#fff\"><path d=\"M35.9.003H12.7a2 2 0 00-2 2v19.6h-1a2 2 0 00-2 2v10.8H2a2 2 0 00-2 2v18.2a6 6 0 006 6h3.2a9.7 9.7 0 0019 0h37.5a9.7 9.7 0 0019 0H88a6 6 0 006-6v-14.2a6 6 0 00-4.6-5.8l-7.6-16.7a8.099 8.099 0 00-7.3-4.6H59.8a2 2 0 00-2 2v19.1h-6.3v-20.9a2 2 0 00-2-2H37.9v-9.5a1.9 1.9 0 00-2-2zm-21.2 4h19.2v7.5h-8a1.899 1.899 0 00-2 2v8.1h-9.2v-17.6zm-3 21.6h12.2v8.8H11.7v-8.8zm7 38.8a5.6 5.6 0 01-5.6-5.6 5.7 5.7 0 015.6-5.7 5.8 5.8 0 015.7 5.7 5.7 5.7 0 01-5.7 5.6zm56.5 0a5.7 5.7 0 01-5.7-5.6 5.7 5.7 0 1111.4 0 5.7 5.7 0 01-5.7 5.6zm14.8-9.8a2 2 0 01-2 2h-3.4a9.6 9.6 0 00-18.8 0H28.1a9.6 9.6 0 00-18.8 0H6a2 2 0 01-2-2v-16.2h84a2 2 0 012 2v14.2zm-28.2-37.3h12.7a4 4 0 013.6 2.3l6.8 14.8H61.8v-17.1zm-14.3 17.1H27.9v-18.9h19.6v18.9z\"/></mask><path d=\"M35.9.003H12.7a2 2 0 00-2 2v19.6h-1a2 2 0 00-2 2v10.8H2a2 2 0 00-2 2v18.2a6 6 0 006 6h3.2a9.7 9.7 0 0019 0h37.5a9.7 9.7 0 0019 0H88a6 6 0 006-6v-14.2a6 6 0 00-4.6-5.8l-7.6-16.7a8.099 8.099 0 00-7.3-4.6H59.8a2 2 0 00-2 2v19.1h-6.3v-20.9a2 2 0 00-2-2H37.9v-9.5a1.9 1.9 0 00-2-2zm-21.2 4h19.2v7.5h-8a1.899 1.899 0 00-2 2v8.1h-9.2v-17.6zm-3 21.6h12.2v8.8H11.7v-8.8zm7 38.8a5.6 5.6 0 01-5.6-5.6 5.7 5.7 0 015.6-5.7 5.8 5.8 0 015.7 5.7 5.7 5.7 0 01-5.7 5.6zm56.5 0a5.7 5.7 0 01-5.7-5.6 5.7 5.7 0 1111.4 0 5.7 5.7 0 01-5.7 5.6zm14.8-9.8a2 2 0 01-2 2h-3.4a9.6 9.6 0 00-18.8 0H28.1a9.6 9.6 0 00-18.8 0H6a2 2 0 01-2-2v-16.2h84a2 2 0 012 2v14.2zm-28.2-37.3h12.7a4 4 0 013.6 2.3l6.8 14.8H61.8v-17.1zm-14.3 17.1H27.9v-18.9h19.6v18.9z\" fill=\"#74747E\" stroke=\"#74747E\" stroke-width=\"2\" mask=\"url(#path-1-inside-1_11574_304076)\"/>",
    "attrs": {
      "width": "94",
      "height": "69",
      "viewBox": "0 0 94 69",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});