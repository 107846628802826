define("ember-svg-jar/inlined/interview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame 4348\"><path id=\"coach_matching\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12.565 10.88c.647 0 1.258.075 1.83.224a7.905 7.905 0 011.532.486c.449.2.797.498 1.047.897.249.398.373.822.373 1.27v1.308h-1.359a3.753 3.753 0 00-6.699 0H7.782v-1.27c0-.474.125-.91.374-1.308.25-.399.598-.698 1.046-.897.449-.2.96-.361 1.532-.486a7.227 7.227 0 011.83-.224zm-6.39.187c.45 0 .823-.15 1.122-.448.323-.324.485-.698.485-1.121 0-.449-.162-.822-.485-1.121a1.462 1.462 0 00-1.121-.486c-.424 0-.797.162-1.121.486-.299.299-.448.672-.448 1.12 0 .424.149.798.448 1.122.324.299.697.448 1.12.448zm.898.897a2.1 2.1 0 00-.411-.038 2.96 2.96 0 00-.486-.037c-.399 0-.785.037-1.158.112a5.672 5.672 0 00-1.047.336 1.513 1.513 0 00-.71.598 1.638 1.638 0 00-.261.897v1.233h3.587v-1.27c0-.648.162-1.258.486-1.831zm11.88-.897c.424 0 .785-.15 1.084-.448.324-.324.486-.698.486-1.121 0-.449-.162-.822-.486-1.121a1.421 1.421 0 00-1.083-.486c-.449 0-.835.162-1.159.486-.298.299-.448.672-.448 1.12 0 .424.15.798.448 1.122.324.299.71.448 1.159.448zm3.177 2.765c0-.324-.088-.623-.262-.897a1.512 1.512 0 00-.71-.598 5.465 5.465 0 00-1.083-.336 5.338 5.338 0 00-1.121-.112 2.96 2.96 0 00-.486.037 2.1 2.1 0 00-.41.038c.323.573.485 1.183.485 1.83v1.27h3.587v-1.232zM12.565 5.5c.448 0 .847.112 1.195.336.374.2.66.486.86.86.224.348.336.747.336 1.195 0 .449-.112.86-.336 1.233-.2.349-.486.635-.86.86-.348.199-.747.298-1.195.298-.449 0-.86-.1-1.233-.299a2.829 2.829 0 01-.86-.859 2.578 2.578 0 01-.298-1.233c0-.448.1-.847.299-1.195.224-.374.51-.66.859-.86a2.352 2.352 0 011.233-.336zm1.992 10.168l-2.47 2.47-1.363-1.377.386-.386.978.978 2.083-2.084.386.4zm-1.917-1.646c-.754 0-1.401.27-1.941.81a2.628 2.628 0 00-.798 1.93c0 .754.266 1.401.798 1.941a2.67 2.67 0 001.941.797c.755 0 1.398-.265 1.93-.797.54-.54.81-1.187.81-1.942 0-.754-.27-1.397-.81-1.929a2.607 2.607 0 00-1.93-.81z\" fill=\"#5A5F68\"/></g>",
    "attrs": {
      "width": "25",
      "height": "25",
      "viewBox": "0 0 25 25",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});