define("ember-moment/helpers/moment", ["exports", "ember-moment/helpers/-base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _base.default.extend({
    compute(params, {
      locale,
      timeZone
    }) {
      this._super(...arguments);
      const moment = this.moment;
      return this.morphMoment(moment.moment(...params), {
        locale,
        timeZone
      });
    }
  });
});