define("ember-svg-jar/inlined/visibility-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11 10.2c-.75 0-1.387-.262-1.912-.787A2.604 2.604 0 018.3 7.5c0-.75.262-1.388.787-1.912A2.604 2.604 0 0111 4.8c.75 0 1.387.263 1.912.788.525.524.788 1.162.788 1.912s-.262 1.387-.787 1.912A2.604 2.604 0 0111 10.2zm0 4.8c-2.433 0-4.65-.68-6.65-2.037C2.35 11.604.9 9.783 0 7.5c.9-2.283 2.35-4.104 4.35-5.463C6.35.68 8.567 0 11 0c2.35 0 4.496.633 6.438 1.9C19.378 3.167 20.832 4.867 21.8 7h-2.275a9.613 9.613 0 00-3.575-3.663A9.594 9.594 0 0011 2a9.545 9.545 0 00-5.188 1.487A9.773 9.773 0 002.2 7.5a9.773 9.773 0 003.612 4.012A9.544 9.544 0 0011 13a10.054 10.054 0 002-.2v2.025c-.333.05-.667.092-1 .125-.333.033-.667.05-1 .05zm0-3a4.558 4.558 0 002.025-.475 4.889 4.889 0 01.787-2.25 5.078 5.078 0 011.688-1.6V7.5c0-1.25-.438-2.313-1.313-3.188C13.313 3.438 12.25 3 11 3c-1.25 0-2.313.438-3.188 1.313S6.5 6.25 6.5 7.5c0 1.25.438 2.313 1.313 3.188C8.688 11.562 9.75 12 11 12zm5 5a.968.968 0 01-.713-.288A.968.968 0 0115 16v-3c0-.283.096-.52.287-.713A.968.968 0 0116 12v-1c0-.55.196-1.02.587-1.412A1.926 1.926 0 0118 9c.55 0 1.02.196 1.413.588.391.391.587.862.587 1.412v1c.283 0 .52.096.712.287.192.192.288.43.288.713v3c0 .283-.096.52-.288.712A.968.968 0 0120 17h-4zm1-5h2v-1a.968.968 0 00-.288-.713A.968.968 0 0018 10a.968.968 0 00-.712.287A.968.968 0 0017 11v1z\" fill=\"#74747E\"/>",
    "attrs": {
      "width": "22",
      "height": "17",
      "viewBox": "0 0 22 17",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});