define("ember-svg-jar/inlined/free", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\" stroke=\"#A6AFBF\" stroke-width=\"3\"><path stroke-linecap=\"round\" d=\"M18.567 11.888h10m-10 12.485h10m-10 12.485h10m-10 12.485h10\"/><rect width=\"136.3\" height=\"104.1\" x=\"1.5\" y=\"1.5\" rx=\"2\"/><rect width=\"34\" height=\"104.1\" x=\"1.5\" y=\"1.5\" rx=\"2\"/><rect width=\"77.2\" height=\"25.3\" x=\"49\" y=\"12.9\" rx=\"4\"/><rect width=\"43.1\" height=\"5.2\" x=\"49\" y=\"50.5\" rx=\"2.6\"/><rect width=\"43.1\" height=\"5.2\" x=\"49\" y=\"60.6\" rx=\"2.6\"/><rect width=\"43.1\" height=\"5.2\" x=\"49\" y=\"70.6\" rx=\"2.6\"/><circle cx=\"10.1\" cy=\"12.1\" r=\"3.4\"/><circle cx=\"10.1\" cy=\"24.1\" r=\"3.4\"/><circle cx=\"10.1\" cy=\"36.1\" r=\"3.4\"/><circle cx=\"10.1\" cy=\"48.1\" r=\"3.4\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 140 108"
    }
  };
});