define("ember-svg-jar/inlined/cross-cultural", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M44 2.75a41.25 41.25 0 100 82.5 41.25 41.25 0 000-82.5zm38.362 38.074l-3.987 3.987-4.331-4.345A30.25 30.25 0 0044.578 13.75l-4.126-4.125L44.578 5.5a38.5 38.5 0 0137.784 35.324zM44 71.5a27.5 27.5 0 110-55 27.5 27.5 0 010 55zM40.521 5.665l-2.997 2.984a1.375 1.375 0 000 1.952l3.327 3.314a30.25 30.25 0 00-26.895 26.551l-4.331 4.345-3.987-3.987A38.5 38.5 0 0140.52 5.665zM5.5 44.578l3.135 3.148a1.375 1.375 0 001.953 0l3.162-3.176a30.25 30.25 0 0027.087 29.535l-3.327 3.314a1.374 1.374 0 000 1.952l2.998 2.984A38.5 38.5 0 015.5 44.578zM44.55 82.5l-4.125-4.125 4.125-4.125a30.25 30.25 0 0029.7-29.7l3.162 3.176a1.374 1.374 0 001.953 0l3.135-3.148A38.5 38.5 0 0144.55 82.5z\" fill=\"#74747E\"/><path d=\"M44.688 23.375A14.451 14.451 0 0030.25 37.813v.467l-2.681 8.03a1.374 1.374 0 00.192 1.237 1.375 1.375 0 001.114.578H33V55a1.375 1.375 0 001.375 1.375H44V60.5a1.375 1.375 0 001.375 1.375H57.75a1.375 1.375 0 001.375-1.375V37.812a14.451 14.451 0 00-14.438-14.437zm11.687 35.75H46.75V55a1.375 1.375 0 00-1.375-1.375H35.75V46.75a1.375 1.375 0 00-1.375-1.375h-3.589L33 38.94c.02-.146.02-.294 0-.44v-.688a11.687 11.687 0 1123.375 0v21.313z\" fill=\"#74747E\"/><path d=\"M38.624 38.211l-1.953 1.953a5.5 5.5 0 007.783 0L42.5 38.21a2.75 2.75 0 01-3.877 0z\" fill=\"#74747E\"/>",
    "attrs": {
      "width": "88",
      "height": "88",
      "viewBox": "0 0 88 88",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});