define("ember-svg-jar/inlined/matching", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.603 15.345l3.18-2.999-.496-.484-2.684 2.53-1.259-1.187-.497.469 1.756 1.67zm-1.79-4.014c.697-.655 1.53-.983 2.502-.983.972 0 1.8.328 2.485.983.696.646 1.044 1.427 1.044 2.343 0 .916-.348 1.702-1.044 2.358-.684.645-1.513.968-2.485.968s-1.805-.323-2.501-.968c-.685-.656-1.027-1.442-1.027-2.358 0-.916.342-1.697 1.027-2.343z\" fill=\"#fff\"/><path d=\"M15.58 6.805a9.85 9.85 0 00-2.359-.272c-.834 0-1.62.09-2.358.272-.738.152-1.396.348-1.973.59-.578.242-1.027.605-1.348 1.089a2.815 2.815 0 00-.481 1.588v1.542H9c.797-1.482 2.43-2.498 4.314-2.498s3.517 1.016 4.315 2.498h1.752v-1.588c0-.544-.16-1.058-.481-1.542-.321-.484-.77-.847-1.348-1.089a10.644 10.644 0 00-1.973-.59zM6.435 6.216c-.385.362-.866.544-1.444.544a2.134 2.134 0 01-1.444-.544c-.385-.394-.578-.847-.578-1.362 0-.544.193-.998.578-1.36.417-.394.898-.59 1.444-.59.578 0 1.059.196 1.444.59.417.362.626.816.626 1.36 0 .515-.209.968-.626 1.362zM5.617 7.803c.192 0 .369.016.53.046a4.252 4.252 0 00-.627 2.223v1.542H.9v-1.497c0-.393.112-.756.337-1.089.224-.332.53-.574.914-.726.45-.181.899-.317 1.348-.408.481-.09.979-.136 1.492-.136.225 0 .433.015.626.045zM22.847 6.216c-.385.362-.85.544-1.396.544a2.198 2.198 0 01-1.492-.544c-.385-.394-.577-.847-.577-1.362 0-.544.192-.998.577-1.36a2.1 2.1 0 011.492-.59c.546 0 1.011.196 1.396.59.417.362.626.816.626 1.36 0 .515-.209.968-.626 1.362zM25.206 9.028c.224.333.337.696.337 1.09v1.496h-4.62v-1.542c0-.787-.21-1.528-.627-2.223.16-.03.337-.046.53-.046a4.04 4.04 0 01.625-.045c.514 0 .995.045 1.444.136.482.09.947.227 1.396.408.385.152.69.394.915.726zM14.761.408A2.913 2.913 0 0013.221 0a3.17 3.17 0 00-1.588.408c-.45.242-.818.59-1.107 1.044a2.745 2.745 0 00-.385 1.452c0 .544.128 1.043.385 1.497.289.423.658.771 1.107 1.043.481.242 1.01.363 1.588.363.578 0 1.091-.12 1.54-.363.482-.272.85-.62 1.107-1.043.29-.454.434-.953.434-1.497 0-.545-.145-1.029-.434-1.452A2.561 2.561 0 0014.761.408z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "26",
      "height": "17",
      "viewBox": "0 0 26 17",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});