define("ember-svg-jar/inlined/legal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0_11757_308323)\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M20.24 6.605V3.643A2.944 2.944 0 0123.183.698h.632a2.944 2.944 0 012.945 2.945v2.962a4.546 4.546 0 011.204 2.27h16.298a.913.913 0 010 1.824h-4.16v6.644l3.518 7.038h.641c1.008 0 1.825.817 1.825 1.824a6.896 6.896 0 01-13.793 0c0-1.007.817-1.824 1.825-1.824h.64l3.52-7.038V10.7H27.963a4.544 4.544 0 01-1.204 2.27v24.945h6.447a5.152 5.152 0 015.152 5.151v1.413a1.824 1.824 0 01-1.824 1.824H10.463a1.824 1.824 0 01-1.824-1.824v-1.413a5.153 5.153 0 015.152-5.151h6.447V12.969a4.547 4.547 0 01-1.204-2.27H8.719v6.644l3.519 7.038h.641c1.008 0 1.824.817 1.824 1.824a6.896 6.896 0 11-13.792 0c0-1.007.816-1.824 1.824-1.824h.641l3.518-7.038V10.7h-4.16a.913.913 0 010-1.824h16.298a4.544 4.544 0 011.205-2.27h.002zm16.296 36.46a3.328 3.328 0 00-3.328-3.327H13.792a3.326 3.326 0 00-3.328 3.327v1.413h26.072v-1.413zM22.064 14.113v23.8h2.873v-23.8a4.557 4.557 0 01-2.873 0zM2.737 26.205a5.072 5.072 0 1010.144 0H2.737zm31.382 0a5.072 5.072 0 0010.144 0H34.119zM5.417 24.381H10.2L7.81 19.598l-2.392 4.783zm31.382 0h4.784l-2.392-4.783-2.392 4.783zM23.5 7.055a2.734 2.734 0 010 5.465 2.734 2.734 0 010-5.465zm1.436-1.593V3.643a1.122 1.122 0 00-1.12-1.12h-.632a1.12 1.12 0 00-1.12 1.12v1.82a4.557 4.557 0 012.872 0z\" fill=\"#5A5F68\"/></g><defs><clipPath id=\"clip0_11757_308323\"><path fill=\"#fff\" d=\"M0 0h47v47H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "47",
      "height": "47",
      "viewBox": "0 0 47 47",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});