define("ember-svg-jar/inlined/change-management", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M25.132.057c.354-.006.693.145.927.411l4.923 5.478a1.202 1.202 0 010 1.61l-5.415 6.01a1.202 1.202 0 11-1.787-1.61l3.586-3.98h-15.97c-6.245 0-8.933 3.745-8.933 7.44 0 3.694 2.688 7.44 8.932 7.44h17.82c7.257 0 11.322 4.873 11.327 9.801.006 4.929-4.047 9.806-11.285 9.806h-16.08l3.12 3.47a1.204 1.204 0 11-1.79 1.609l-4.924-5.478a1.203 1.203 0 010-1.61l5.415-6.01a1.202 1.202 0 111.788 1.61l-3.605 4.002h16.076c6.212 0 8.883-3.723 8.878-7.396-.004-3.672-2.687-7.399-8.921-7.399h-17.82c-7.27 0-11.339-4.897-11.339-9.846 0-4.95 4.069-9.847 11.339-9.847h16.013l-3.14-3.492h.001a1.202 1.202 0 01.863-2.02v.001z\" fill=\"#5A5F68\"/>",
    "attrs": {
      "width": "41",
      "height": "48",
      "viewBox": "0 0 41 48",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});