define("ember-svg-jar/inlined/task-alt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M20.484 3.203l-11.39 11.39-4.266-4.218 1.453-1.406 2.813 2.812 9.984-9.984 1.406 1.406zM18.281 8.22c.063.281.11.578.14.89.063.282.095.579.095.891a7.43 7.43 0 01-.657 3.094 7.979 7.979 0 01-1.734 2.578 8.476 8.476 0 01-2.531 1.734c-.969.407-2 .61-3.094.61a7.917 7.917 0 01-3.094-.61 9.119 9.119 0 01-2.578-1.734 9.12 9.12 0 01-1.734-2.578c-.406-.969-.61-2-.61-3.094 0-1.094.204-2.125.61-3.094a8.476 8.476 0 011.734-2.531 7.978 7.978 0 012.578-1.734 7.43 7.43 0 013.094-.657c.781 0 1.531.11 2.25.329a7.9 7.9 0 012.016.937l1.453-1.453c-.813-.563-1.703-1-2.672-1.313A9.863 9.863 0 0010.5.016a9.63 9.63 0 00-3.89.796A9.919 9.919 0 003.421 2.97a10.254 10.254 0 00-2.156 3.187C.766 7.344.516 8.625.516 10s.25 2.672.75 3.89a11.038 11.038 0 002.156 3.188c.937.906 2 1.625 3.187 2.156 1.22.5 2.516.75 3.891.75s2.656-.25 3.844-.75a10.254 10.254 0 003.187-2.156c.938-.937 1.657-2 2.157-3.187A9.63 9.63 0 0020.483 10c0-.594-.047-1.172-.14-1.734a9.726 9.726 0 00-.422-1.641l-1.64 1.594z\" fill=\"#5E1DE1\"/>",
    "attrs": {
      "width": "21",
      "height": "20",
      "viewBox": "0 0 21 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});