define("ember-svg-jar/inlined/healthcare", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M66.005 21.174l-.08.569h7.7a1.874 1.874 0 011.875 1.876 1.877 1.877 0 01-1.875 1.876h-8.663l-.12.33c-2.93 8.134-9.142 15.567-14.77 21.06-5.622 5.486-10.613 8.992-11.04 9.274h0l-.01.006a1.874 1.874 0 01-2.138 0c-.415-.288-5.384-3.796-10.985-9.28-5.61-5.493-11.81-12.926-14.741-21.06l-.12-.33H2.376a1.874 1.874 0 01-1.326-3.203c.352-.352.829-.55 1.326-.55h7.699l-.079-.568c-.6-4.332.376-8.736 2.75-12.408a18.227 18.227 0 0110.181-7.597 18.028 18.028 0 0114.799 2.313l.275.182.275-.182a18.032 18.032 0 0114.8-2.313 18.228 18.228 0 0110.18 7.597 18.243 18.243 0 012.75 12.408zM23.955 4.783h0a14.501 14.501 0 00-8.28 6.314 14.513 14.513 0 00-1.917 10.236l.076.41h9.137l.147-.147 6.435-6.426s0 0 0 0a1.874 1.874 0 012.545-.091l.326-.38-.326.38a1.876 1.876 0 01.53.757l5.498 14.66.342.913.542-.812 6.935-10.396h0a1.875 1.875 0 012.145-.741h.002l6.755 2.257.077.026h7.243l.075-.41a14.513 14.513 0 00-1.926-10.222 14.502 14.502 0 00-8.27-6.304l-.003-.001a14.268 14.268 0 00-12.7 2.462l-.002.002c-.328.26-.734.402-1.152.404h-.378a1.874 1.874 0 01-1.152-.404l-.002-.001a14.342 14.342 0 00-8.81-3.04h0a14.008 14.008 0 00-3.892.554zm13.742 47.284l.303.23.303-.23a98.95 98.95 0 0010.805-9.592c4.393-4.538 8.924-10.21 11.5-16.297l.293-.695H54.621a1.788 1.788 0 01-.585-.093h0l-5.404-1.806-.362-.121-.212.318-8.503 12.748h0a1.877 1.877 0 01-1.556.835h-.24a1.876 1.876 0 01-1.51-1.207l-.001-.001-5.831-15.552-.273-.728-.549.55-4.523 4.525s0 0 0 0a1.875 1.875 0 01-1.322.544h0H15.098l.295.695c2.576 6.082 7.106 11.75 11.5 16.287a99.05 99.05 0 0010.804 9.59zm-21.3-26.072h7.353-7.353z\" fill=\"#74747E\" stroke=\"#74747E\"/>",
    "attrs": {
      "width": "76",
      "height": "57",
      "viewBox": "0 0 76 57",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});