define("ember-svg-jar/inlined/engineer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M41.175 12.15A3.15 3.15 0 0038.07 9l-13.815-.09V5.4a4.05 4.05 0 00-8.1 0v3.465h-2.7V4.68a.9.9 0 00-.9-.9H7.83a4.005 4.005 0 00-4.005 3.915v22.41A4.006 4.006 0 007.83 34.11h18.945v4.5a5.04 5.04 0 1010.08 0v-4.5h1.17a3.15 3.15 0 003.15-3.15V12.15zm-23.22-4.14h4.5v14.085l-2.25 3.78-2.25-3.78V8.01zm2.25-4.86a2.25 2.25 0 012.25 2.25v.81h-4.5V5.4a2.25 2.25 0 012.25-2.25zM5.625 7.785A2.205 2.205 0 017.83 5.58h3.825v18.9H7.83c-.785.01-1.55.243-2.205.675V7.785zm26.19 34.065a3.24 3.24 0 01-3.24-3.24V17.865h6.48v2.88h-1.89v1.8h1.89v2.34h-1.89v1.8h1.89v2.295h-1.89v1.8h1.89v2.295h-1.89v1.8h1.89v3.735a3.24 3.24 0 01-3.24 3.24zm7.56-10.89a1.35 1.35 0 01-1.35 1.35h-1.17V16.965a.9.9 0 00-.9-.9h-8.28a.9.9 0 00-.9.9V32.31H7.83a2.205 2.205 0 01-2.205-2.205v-1.62A2.205 2.205 0 017.83 26.28h4.725a.9.9 0 00.9-.9V10.62h2.7v11.7c.002.16.049.316.135.45l3.15 5.265a.945.945 0 001.53 0l3.15-5.265a.856.856 0 00.135-.45V10.71l13.77.09a1.35 1.35 0 011.35 1.35v18.81z\" fill=\"#5A5F68\"/><path d=\"M31.815 36.225a2.34 2.34 0 00-2.385 2.34 2.385 2.385 0 004.77 0 2.34 2.34 0 00-2.385-2.34zm0 2.925a.585.585 0 110-1.17.585.585 0 010 1.17z\" fill=\"#5A5F68\"/>",
    "attrs": {
      "width": "45",
      "height": "45",
      "viewBox": "0 0 45 45",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});