define("ember-svg-jar/inlined/on-demand-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0_7781_275915)\" fill=\"#5E1DE1\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12.097 2.483a9.38 9.38 0 100 18.758 9.38 9.38 0 000-18.758zM.56 11.863C.559 5.49 5.725.322 12.097.322c6.373 0 11.539 5.167 11.539 11.54 0 6.372-5.166 11.538-11.539 11.538C5.725 23.4.56 18.235.56 11.862z\"/><path d=\"M11.594 2.483v.5h.5a8.88 8.88 0 018.879 8.88v.5h3.16v-.5c0-6.65-5.39-12.04-12.04-12.04h-.5v2.66z\" stroke=\"#fff\"/><path d=\"M12.018 18.322c1.304 0 2.484-.37 3.541-1.109 1.082-.764 1.857-1.738 2.324-2.92-.738-.592-1.746-.888-3.024-.888-.713 0-1.476.16-2.287.48-.787.296-1.18.69-1.18 1.184v3.216c.147.025.356.037.626.037zm-1.88-3.253c0-.863.491-1.566 1.475-2.108-.615-.123-1.107-.185-1.476-.185-.664 0-1.414.124-2.25.37-.836.222-1.438.542-1.807.961.344.937.873 1.75 1.586 2.44a6.2 6.2 0 002.471 1.48v-2.958zm1.29-6.655a1.69 1.69 0 00-1.29-.555c-.517 0-.96.185-1.329.555-.368.37-.553.813-.553 1.33 0 .518.185.962.553 1.332.37.345.812.517 1.328.517.517 0 .947-.172 1.291-.517.37-.37.554-.814.554-1.331 0-.518-.185-.962-.554-1.331zm4.5 1.146a1.456 1.456 0 00-1.07-.444c-.417 0-.774.148-1.07.444a1.463 1.463 0 00-.442 1.072c0 .42.148.777.443 1.072.295.296.652.444 1.07.444.418 0 .774-.148 1.07-.444.294-.296.442-.653.442-1.072 0-.419-.148-.776-.443-1.072zm-3.91-5.435c2.164 0 4.009.776 5.533 2.33 1.55 1.527 2.324 3.376 2.324 5.545s-.775 4.03-2.324 5.583c-1.524 1.528-3.369 2.292-5.533 2.292-2.188 0-4.057-.764-5.606-2.292C4.887 16.03 4.125 14.169 4.125 12s.762-4.018 2.287-5.546c1.55-1.553 3.418-2.329 5.606-2.329z\"/></g><defs><clipPath id=\"clip0_7781_275915\"><path fill=\"#fff\" d=\"M0 0h24v24H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});