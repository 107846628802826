define("ember-svg-jar/inlined/metric", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M0 0h24v24H0V0z\" fill=\"none\"/><path d=\"M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zM7.64 15H6.49v-4.5l-.9.66-.58-.89L6.77 9h.87v6zm5.86 0H9.61v-1.02c1.07-1.07 1.77-1.77 2.13-2.15.4-.42.54-.69.54-1.06 0-.4-.31-.72-.81-.72-.52 0-.8.39-.9.72l-1.01-.42c.01-.02.18-.76 1-1.15.69-.33 1.48-.2 1.95.03.86.44.91 1.24.91 1.48 0 .64-.31 1.26-.92 1.86-.25.25-.72.71-1.4 1.39l.03.05h2.37V15zm5.25-.85c-.08.13-.56.85-1.76.85-.04 0-1.6.08-2.05-1.51l1.03-.41c.03.1.19.86 1.02.86.41 0 .89-.28.89-.77 0-.55-.48-.79-1.04-.79h-.5v-1h.46c.33 0 .88-.14.88-.72 0-.39-.31-.65-.75-.65-.5 0-.74.32-.85.64l-.99-.41C15.2 9.9 15.68 9 16.94 9c1.09 0 1.54.64 1.62.75.33.5.28 1.16.02 1.57-.15.22-.32.38-.52.48v.07c.28.11.51.28.68.52.37.52.33 1.27.01 1.76z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "height": "24",
      "viewBox": "0 0 24 24",
      "width": "24",
      "fill": "currentColor"
    }
  };
});