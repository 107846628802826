define("ember-svg-jar/inlined/file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\" stroke=\"#A6AFBF\" stroke-width=\"3\"><g opacity=\".2\"><g transform=\"translate(3 3)\"><path d=\"M85.5 15.522L70.949 1.5H4A2.5 2.5 0 001.5 4v94a2.5 2.5 0 002.5 2.5h79a2.5 2.5 0 002.5-2.5V15.522z\"/><rect width=\"53\" height=\"1\" x=\"18.5\" y=\"29.5\" rx=\".5\"/><rect width=\"53\" height=\"1\" x=\"18.5\" y=\"47.5\" rx=\".5\"/><rect width=\"53\" height=\"1\" x=\"18.5\" y=\"65.5\" rx=\".5\"/></g><path d=\"M85.786 17.5L74.5 6.901V15a2.5 2.5 0 002.5 2.5h8.786z\"/></g><path d=\"M85.5 15.522L70.949 1.5H4A2.5 2.5 0 001.5 4v94a2.5 2.5 0 002.5 2.5h79a2.5 2.5 0 002.5-2.5V15.522z\"/><rect width=\"54\" height=\"1\" x=\"17.5\" y=\"29.5\" rx=\".5\"/><rect width=\"54\" height=\"1\" x=\"17.5\" y=\"47.5\" rx=\".5\"/><rect width=\"54\" height=\"1\" x=\"17.5\" y=\"65.5\" rx=\".5\"/><path d=\"M82.786 14.5L71.5 3.901V12a2.5 2.5 0 002.5 2.5h8.786z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "90",
      "height": "105",
      "viewBox": "0 0 90 105"
    }
  };
});