define("ember-svg-jar/inlined/time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 21a9 9 0 10-9-9 9.011 9.011 0 009 9zm0-16.5A7.5 7.5 0 114.5 12 7.51 7.51 0 0112 4.5zM12 24A12 12 0 100 12a12.013 12.013 0 0012 12zm0-22.5A10.5 10.5 0 111.5 12 10.512 10.512 0 0112 1.5zm-.28 12.732a2.242 2.242 0 002.399-1.482h1.63a.75.75 0 000-1.5h-1.638a2.165 2.165 0 00-1.55-1.419V7.309a.75.75 0 10-1.5 0V9.96a2.247 2.247 0 00.658 4.271zm-.18-2.824a.74.74 0 01.554-.152.75.75 0 11-.554.152zM6.714 36h-1.5a2.252 2.252 0 00-2.25 2.25v9a.75.75 0 00.75.75h4.5a.75.75 0 00.75-.75v-9A2.252 2.252 0 006.714 36zm.75 10.5h-3v-8.25a.75.75 0 01.75-.75h1.5a.75.75 0 01.75.75v8.25zm11.25-15h-1.5a2.252 2.252 0 00-2.25 2.25v13.5a.75.75 0 00.75.75h4.5a.75.75 0 00.75-.75v-13.5a2.252 2.252 0 00-2.25-2.25zm.75 15h-3V33.75a.75.75 0 01.75-.75h1.5a.75.75 0 01.75.75V46.5zm11.25-21h-1.5a2.252 2.252 0 00-2.25 2.25v19.5a.75.75 0 00.75.75h4.5a.75.75 0 00.75-.75v-19.5a2.252 2.252 0 00-2.25-2.25zm.75 21h-3V27.75a.75.75 0 01.75-.75h1.5a.75.75 0 01.75.75V46.5zM42.714 15h-1.5a2.252 2.252 0 00-2.25 2.25v30a.75.75 0 00.75.75h4.5a.75.75 0 00.75-.75v-30a2.252 2.252 0 00-2.25-2.25zm.75 31.5h-3V17.25a.75.75 0 01.75-.75h1.5a.75.75 0 01.75.75V46.5zM46.46 6.51L44.925 1.94a.75.75 0 00-.96-.47l-4.464 1.572a.75.75 0 10.498 1.415l2.638-.928A58.437 58.437 0 0127.26 21.152 60.664 60.664 0 013.547 31.52a.75.75 0 00.334 1.462 62.12 62.12 0 0024.286-10.633 59.96 59.96 0 0015.89-18.284l.981 2.925a.75.75 0 101.423-.477z\" fill=\"#5A5F68\"/>",
    "attrs": {
      "width": "48",
      "height": "48",
      "viewBox": "0 0 48 48",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});