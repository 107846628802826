define("ember-svg-jar/inlined/breakthrough", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M28.308 21.04a2.127 2.127 0 00-.024 4.248 2.128 2.128 0 00.83-4.088 2.129 2.129 0 00-.814-.165l.008.006zM11.638 8.285a2.127 2.127 0 103.603-2.262 2.127 2.127 0 00-3.603 2.262zM7.847 29.554a2.127 2.127 0 10-1.055-4.122 2.127 2.127 0 101.047 4.122h.008zM26.056 19.224a2.288 2.288 0 10.02-4.57 2.287 2.287 0 000 4.57h-.02zM23.557 13.073a2.122 2.122 0 002.127-2.115 2.131 2.131 0 00-2.127-2.135 2.127 2.127 0 000 4.253v-.003zM20.125 7.423a1.89 1.89 0 10-.72-.141c.235.096.486.144.739.141h-.02zM15.774 2.991a1.49 1.49 0 001.495-1.487A1.495 1.495 0 0015.795 0a1.496 1.496 0 00-.021 2.991zM10.805 3.086a1.18 1.18 0 000-2.357 1.178 1.178 0 100 2.357zM18.189 13.419a1.493 1.493 0 00.498-2.665 1.469 1.469 0 00-.863-.272 1.507 1.507 0 00-1.289.732 1.495 1.495 0 001.284 2.257c.125-.001.25-.019.37-.052zM15.812 28.776a2.279 2.279 0 00-2.782-1.65 2.284 2.284 0 00.568 4.497 2.296 2.296 0 001.967-1.115 2.26 2.26 0 00.247-1.732zM18.121 29.137a2.126 2.126 0 103.656 2.171 2.126 2.126 0 00-3.656-2.17zM27.971 30.997a1.903 1.903 0 10-1.632.922 1.878 1.878 0 001.632-.922zM31.275 29.616a1.499 1.499 0 002.26-1.362 1.495 1.495 0 10-2.26 1.362zM33.47 24.765a1.174 1.174 0 001.296-1.957 1.178 1.178 0 00-1.641 1.65c.093.125.21.229.345.307zM20.987 23.928a1.49 1.49 0 00.52 2.048 1.471 1.471 0 001.133.165 1.495 1.495 0 10-1.653-2.213zM8.464 14.202A2.288 2.288 0 009.89 9.878a2.287 2.287 0 10-1.426 4.324zM4.812 19.363a2.124 2.124 0 10.931-4.145 2.124 2.124 0 00-.931 4.144zM2.138 24.924a1.904 1.904 0 10.834-3.716 1.904 1.904 0 00-.834 3.716zM1.168 30.38a1.475 1.475 0 001.128-.198 1.495 1.495 0 10-1.128.195v.003zM4.399 33.958a1.181 1.181 0 10.516-2.3 1.187 1.187 0 00-.886.155 1.174 1.174 0 00.37 2.145zM10.952 22.223a1.495 1.495 0 10-2.747-1.18 1.495 1.495 0 002.747 1.18z\" fill=\"#CF0000\"/>",
    "attrs": {
      "width": "36",
      "height": "34",
      "viewBox": "0 0 36 34",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});