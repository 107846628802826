define("ember-svg-jar/inlined/stripe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16.492 2.118l-4.33.941-.02 14.57c0 2.69 1.98 4.43 4.61 4.43 1.46 0 2.531-.277 3.121-.604v-3.458c-.57.235-3.37 1.073-3.37-1.607V9.925h3.37V6.057h-3.37l-.01-3.94zm8.912 5.28l-.27-1.341h-3.84V21.73h4.43V11.183c1.05-1.412 2.821-1.136 3.391-.952V6.057c-.6-.215-2.671-.614-3.71 1.34h-.001zM34.635 0l-4.46.972v3.704l4.46-.972V0zM4.481 10.671c0-.706.58-.982 1.51-.993a9.776 9.776 0 014.42 1.167V6.568a11.618 11.618 0 00-4.41-.83C2.401 5.74 0 7.664 0 10.878c0 5.033 6.751 4.215 6.751 6.384 0 .839-.71 1.115-1.7 1.115-1.47 0-3.37-.625-4.862-1.454v4.093a12.07 12.07 0 004.851 1.033c3.69 0 6.231-1.616 6.231-4.89 0-5.413-6.791-4.44-6.791-6.487zM64 14.078c0-4.655-2.2-8.328-6.42-8.328-4.221 0-6.792 3.673-6.792 8.298 0 5.474 3.03 8.001 7.351 8.001 2.12 0 3.71-.491 4.92-1.177v-3.418a9.219 9.219 0 01-4.36 1.003c-1.73 0-3.25-.624-3.45-2.752h8.691c.02-.236.06-1.187.06-1.627zm-8.791-1.719c0-2.046 1.23-2.905 2.34-2.905 1.09 0 2.25.86 2.25 2.905h-4.59zM43.917 5.75a4.946 4.946 0 00-3.48 1.422l-.23-1.125h-3.912V27l4.44-.962.01-5.136a4.907 4.907 0 003.141 1.147c3.18 0 6.082-2.374 6.082-8.144.01-5.28-2.93-8.154-6.051-8.154V5.75zm-1.06 12.533a2.615 2.615 0 01-1.138-.193 2.667 2.667 0 01-.953-.666l-.03-6.753c.266-.302.594-.54.961-.696a2.611 2.611 0 011.16-.204c1.62 0 2.74 1.862 2.74 4.235.01 2.446-1.09 4.277-2.74 4.277zm-12.672 3.448h4.46V6.057h-4.46V21.73z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "64",
      "height": "27",
      "viewBox": "0 0 64 27",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});