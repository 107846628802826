define("ember-group-by/macros/group-by", ["exports", "@ember/array", "@ember/object", "@ember/utils"], function (_exports, _array, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = groupBy;
  function groupBy(collection, property) {
    let dependentKey = collection + '.@each.' + property;
    return (0, _object.computed)(dependentKey, function () {
      let groups = (0, _array.A)();
      let items = (0, _object.get)(this, collection);
      if (items) {
        items.forEach(function (item) {
          let value = (0, _object.get)(item, property);
          let group = groups.findBy('value', value);
          if ((0, _utils.isPresent)(group)) {
            group.items.push(item);
          } else {
            group = {
              property: property,
              value: value,
              items: [item]
            };
            groups.push(group);
          }
        });
      }
      return groups;
    }).readOnly();
  }
});