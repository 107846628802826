define("ember-svg-jar/inlined/cart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0_11757_308411)\" fill=\"#5A5F68\"><path d=\"M38.215 23.74l2.096-10.632a1.176 1.176 0 00-1.151-1.41H34.6V4.836a1.176 1.176 0 00-1.174-1.175H25.2V1.645A1.176 1.176 0 0024.002.47h-9.428a1.176 1.176 0 00-1.175 1.175v10.058H9.432L8.281 6.481a4.201 4.201 0 00-4.08-3.29h-2.35a1.175 1.175 0 000 2.35h2.35a1.838 1.838 0 011.781 1.434l4.465 20.355.823 3.953a4.192 4.192 0 004.089 3.328h20.605a1.175 1.175 0 000-2.35h-20.6a1.833 1.833 0 01-1.787-1.453l-.667-3.224h20.614a4.79 4.79 0 004.691-3.844zM32.251 6.01v5.692H25.2V6.011h7.05zM15.749 2.82h7.102v8.883h-7.102V2.82zm21.982 11.233l-1.824 9.23a2.435 2.435 0 01-2.382 1.96H12.403l-2.458-11.19H37.73zM13.746 40.3a4.23 4.23 0 108.46 0 4.23 4.23 0 00-8.46 0zm6.11 0a1.88 1.88 0 11-1.88-1.88 1.88 1.88 0 011.857 1.88h.023zM26.281 40.3a4.23 4.23 0 108.46 0 4.23 4.23 0 00-8.46 0zm6.11 0a1.88 1.88 0 11-1.88-1.88 1.88 1.88 0 011.857 1.88h.023z\"/></g><defs><clipPath id=\"clip0_11757_308411\"><path fill=\"#fff\" d=\"M0 0h41v45H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "41",
      "height": "45",
      "viewBox": "0 0 41 45",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});