define("ember-svg-jar/inlined/messaging", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><rect id=\"a\" width=\"78\" height=\"64\" rx=\"8\"/></defs><g fill=\"none\" fill-rule=\"evenodd\"><g stroke-width=\"3\" transform=\"translate(53 37)\"><rect width=\"75\" height=\"61\" x=\"3.5\" y=\"3.5\" stroke=\"#EEF1F9\" rx=\"8\"/><rect width=\"75\" height=\"61\" x=\"1.5\" y=\"1.5\" stroke=\"#B9C8D9\" rx=\"8\"/></g><path d=\"M68.5 56.5h51m-51 11h51m-51 11h26.02\" stroke=\"#EEF1F9\" stroke-linecap=\"round\" stroke-width=\"3\"/><path d=\"M66.5 54.5h51m-51 11h51m-51 11h26.02\" stroke=\"#B9C8D9\" stroke-linecap=\"round\" stroke-width=\"3\"/><rect width=\"75\" height=\"61\" x=\"3.5\" y=\"3.5\" stroke=\"#EEF1F9\" stroke-width=\"3\" rx=\"8\"/><use fill=\"#F7F9FA\" xlink:href=\"#a\"/><rect width=\"75\" height=\"61\" x=\"1.5\" y=\"1.5\" stroke=\"#B9C8D9\" stroke-width=\"3\" rx=\"8\"/><g stroke=\"#EEF1F9\" stroke-linecap=\"round\" stroke-width=\"3\"><path d=\"M15.5 17.5h51m-51 11h51m-51 11h26.02\"/></g><g stroke=\"#B9C8D9\" stroke-linecap=\"round\" stroke-width=\"3\"><path d=\"M13.5 15.5h51m-51 11h51m-51 11h26.02\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "width": "133",
      "height": "103",
      "viewBox": "0 0 133 103"
    }
  };
});