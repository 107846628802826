define("ember-svg-jar/inlined/exchange", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#0071C5\" d=\"M11.772.701L.234 3.01v15.384l11.538 2.308v-20zm.77 4.616V6.71h.312c.078 0 .144-.006.144.072l1.01.985c.078.079.078.163 0 .24l-.385.385c-.078.079-.162.079-.24 0l-.457-.456h-.385v2.692c.691.616 1.313 1.067 1.082 1.298-.078.153-.544-.078-1.082-.385v2.765h.144c1.232-.923 2.242-2.614 2.549-2.308.384.307-2.074 3.558-2.38 3.558h-.313v1.298h6.683c.538 0 1.01-.472 1.01-1.01V6.326c0-.538-.472-1.01-1.01-1.01H12.54zm-4.688.312v1.683l-2.62.168v1.923l2.38-.096v1.707h-2.38v1.995l2.764.144v1.707l-4.375-.385v-8.39l4.23-.456zm8.149 1.082h2.62c.153 0 .216.063.216.216v2.476c0 .078.006.144-.072.144l-.985 1.01c-.079.078-.163.078-.24 0l-.385-.313c-.079-.078-.079-.162 0-.24l.456-.457v-1.61h-1.37c-1.232.922-2.241 2.614-2.548 2.307-.385-.306 2.001-3.455 2.308-3.533zm-.53 3.557c.61-.045 3.462 2.14 3.462 2.356v1.61h-.096v1.01c0 .154-.063.217-.216.217h-2.476c-.078 0-.144.006-.144-.072l-1.01-.986c-.078-.078-.078-.162 0-.24l.385-.385c.078-.078.162-.078.24 0l.457.457h1.61v-1.37c-.922-1.232-2.614-2.242-2.307-2.548a.141.141 0 01.096-.049z\"/>",
    "attrs": {
      "viewBox": "0 0 23 23",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});