define("ember-svg-jar/inlined/logo-white-bg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><linearGradient id=\"logo-white-a\" x1=\"19\" x2=\"0\" y1=\"2\" y2=\"21\" gradientTransform=\"translate(4 2)\" gradientUnits=\"userSpaceOnUse\"><stop offset=\"0%\" stop-color=\"#5E1DE1\"/><stop offset=\"100%\" stop-color=\"#8E41FF\"/></linearGradient></defs><rect width=\"100%\" height=\"100%\" fill=\"#fff\"/><path fill=\"url(#logo-white-a)\" fill-rule=\"evenodd\" d=\"M9.865 4L6.93 6.933h13.137v13.135L23 17.136V4zM4 9.865V23h13.135l2.933-2.932H6.931V6.933z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 27 27"
    }
  };
});