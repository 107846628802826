define("ember-svg-jar/inlined/government", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M85.616 50.653c.771 0 1.396-.626 1.396-1.396V42.07c0-.598-.381-1.13-.947-1.322L51.396 28.967v-9.658h5.897v2.098c0 .771.626 1.396 1.396 1.396H67.1c.771 0 1.396-.626 1.396-1.396v-6.99c0-.771-.626-1.396-1.396-1.396h-7.292v-2.098c0-.771-.626-1.396-1.396-1.396H50c-.771 0-1.396.626-1.396 1.396v18.045l-34.669 11.78a1.396 1.396 0 00-.947 1.322v7.187c0 .771.626 1.396 1.396 1.396h4.858V79.27h-4.858c-.771 0-1.396.626-1.396 1.396v8.411c0 .771.626 1.396 1.396 1.396h71.231c.771 0 1.396-.626 1.396-1.396v-8.411c0-.771-.626-1.396-1.396-1.396h-4.858V50.653h4.859zm-25.53-34.839h5.618v4.197h-5.618v-4.197zm-8.69-3.495h5.619v4.197h-5.619v-4.197zM15.781 43.07L50 31.441 84.219 43.07v4.79H15.781v-4.79zm47.598 36.2V50.653h7.998V79.27h-7.998zm-17.378 0V50.653h7.998V79.27h-7.998zm-17.378 0V50.653h7.998V79.27h-7.998zm10.791-28.617h3.794V79.27h-3.794V50.653zm17.378 0h3.794V79.27h-3.794V50.653zm-34.757 0h3.795V79.27h-3.795V50.653zm62.184 37.028H15.781v-5.618H84.22v5.618h-.001zm-6.254-8.411H74.17V50.653h3.795V79.27z\" fill=\"#74747E\"/>",
    "attrs": {
      "width": "100",
      "height": "100",
      "viewBox": "0 0 100 100",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});