define("ember-yeti-table/components/yeti-table/thead/row/cell/component", ["exports", "@ember-decorators/component", "@ember/component", "@ember/object/computed", "ember-yeti-table/components/yeti-table/thead/row/cell/template"], function (_exports, _component, _component2, _computed, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _class2, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
    An component yielded from the head.row component that is used to define
    a cell in a row of the head of the table. Would be used for filters or any other
    additional information in the table head for a column
  
    ```hbs
    <table.thead as |head|>
      <head.row as |row|>
        <row.cell>
          <input
            class="input" type="search" placeholder="Search last name"
            value={{this.lastNameFilter}}
            oninput={{action (mut this.lastNameFilter) value="target.value"}}>
        </row.cell>
      </head.row>
    </table.thead>
    ```
  
    @yield {object} cell
  
   */
  let THeadCell = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec3 = (0, _computed.reads)('column.visible'), _dec(_class = _dec2(_class = (_class2 = class THeadCell extends _component2.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "theme", void 0);
      _defineProperty(this, "parent", void 0);
      /**
       * Controls the visibility of the current cell. Keep in mind that this property
       * won't just hide the column using css. The DOM for the column will be removed.
       * Defaults to the `visible` argument of the corresponding column.
       */
      _initializerDefineProperty(this, "visible", _descriptor, this);
    }
    init() {
      super.init(...arguments);
      if (this.get('parent')) {
        this.get('parent').registerCell(this);
      }
    }
    willDestroyElement() {
      super.willDestroyElement(...arguments);
      if (this.get('parent')) {
        this.get('parent').unregisterCell(this);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "visible", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class) || _class);
  var _default = _exports.default = THeadCell;
});