define("ember-svg-jar/inlined/stripe-connected", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3.773 13.227C5.102 14.529 6.677 15.18 8.5 15.18c1.823 0 3.385-.651 4.688-1.953 1.328-1.329 1.992-2.904 1.992-4.727 0-1.823-.664-3.385-1.992-4.688C11.884 2.484 10.322 1.82 8.5 1.82c-1.823 0-3.398.664-4.727 1.992C2.471 5.115 1.82 6.678 1.82 8.5c0 1.823.651 3.398 1.953 4.727zM2.602 2.64C4.242 1 6.208.18 8.5.18s4.245.82 5.86 2.46C16 4.256 16.82 6.209 16.82 8.5c0 2.292-.82 4.258-2.46 5.898-1.615 1.615-3.568 2.422-5.86 2.422-2.292 0-4.258-.807-5.898-2.422C.987 12.758.18 10.792.18 8.5s.807-4.245 2.422-5.86zm9.726 2.187L13.5 6l-6.68 6.68L2.68 8.5l1.172-1.172 2.968 2.969 5.508-5.469z\" fill=\"#02DC94\"/>",
    "attrs": {
      "width": "17",
      "height": "17",
      "viewBox": "0 0 17 17",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});