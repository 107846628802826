define("ember-svg-jar/inlined/tip-purple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0_2231_6419)\"><path d=\"M7.377 20h4.157c0 1.1-.936 2-2.079 2-1.143 0-2.078-.9-2.078-2zm-2.08-1h8.316v-2H5.298v2zM17.25 9.5c0 3.82-2.764 5.86-3.918 6.5H5.578c-1.153-.64-3.918-2.68-3.918-6.5C1.66 5.36 5.152 2 9.455 2s7.795 3.36 7.795 7.5zm5.062-2.13L20.888 8l1.424.63.655 1.37.655-1.37L25.044 8l-1.424-.63L22.968 6l-.655 1.37zM19.849 6l.977-2.06 2.14-.94-2.14-.94L19.849 0l-.977 2.06L16.73 3l2.14.94L19.85 6z\" fill=\"#601FE3\"/></g><defs><clipPath id=\"clip0_2231_6419\"><path fill=\"#fff\" transform=\"translate(.102)\" d=\"M0 0h24.944v24H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "26",
      "height": "24",
      "viewBox": "0 0 26 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});