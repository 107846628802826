define("ember-svg-jar/inlined/edge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" d=\"M481.92 134.48C440.87 54.18 352.26 8 255.91 8 137.05 8 37.51 91.68 13.47 203.66c26-46.49 86.22-79.14 149.46-79.14 79.27 0 121.09 48.93 122.25 50.18 22 23.8 33 50.39 33 83.1 0 10.4-5.31 25.82-15.11 38.57-1.57 2-6.39 4.84-6.39 11 0 5.06 3.29 9.92 9.14 14 27.86 19.37 80.37 16.81 80.51 16.81A115.39 115.39 0 00444.94 322a118.92 118.92 0 0058.95-102.44c.5-43.43-15.5-72.3-21.97-85.08zM212.77 475.67a154.88 154.88 0 01-46.64-45c-32.94-47.42-34.24-95.6-20.1-136A155.5 155.5 0 01203 215.75c59-45.2 94.84-5.65 99.06-1a80 80 0 00-4.89-10.14c-9.24-15.93-24-36.41-56.56-53.51-33.72-17.69-70.59-18.59-77.64-18.59-38.71 0-77.9 13-107.53 35.69C35.68 183.3 12.77 208.72 8.6 243c-1.08 12.31-2.75 62.8 23 118.27a248 248 0 00248.3 141.61c-38.12-6.62-65.85-26.64-67.13-27.21zm250.72-98.33a7.76 7.76 0 00-7.92-.23 181.66 181.66 0 01-20.41 9.12 197.54 197.54 0 01-69.55 12.52c-91.67 0-171.52-63.06-171.52-144a61.12 61.12 0 016.52-26.75 168.72 168.72 0 00-38.76 50c-14.92 29.37-33 88.13 13.33 151.66 6.51 8.91 23 30 56 47.67 23.57 12.65 49 19.61 71.7 19.61 35.14 0 115.43-33.44 163-108.87a7.75 7.75 0 00-2.39-10.73z\"/>",
    "attrs": {
      "aria-hidden": "true",
      "data-prefix": "fab",
      "data-icon": "edge",
      "class": "svg-inline--fa fa-edge fa-w-16",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
});