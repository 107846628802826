define("ember-svg-jar/inlined/safari", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" d=\"M274.69 274.69l-37.38-37.38L166 346zM256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm155.85 174.79l14.78-6.13a8 8 0 0110.45 4.34 8 8 0 01-4.33 10.46L418 197.57a8 8 0 01-10.45-4.33 8 8 0 014.3-10.45zM314.43 94l6.12-14.78a8 8 0 0110.45-4.3 8 8 0 014.33 10.45l-6.13 14.78a8 8 0 01-10.45 4.33A8 8 0 01314.43 94zM256 60a8 8 0 018 8v16a8 8 0 01-8 8 8 8 0 01-8-8V68a8 8 0 018-8zm-75 14.92a8 8 0 0110.46 4.33L197.57 94a8 8 0 11-14.78 6.12l-6.13-14.78A8 8 0 01181 74.92zm-63.58 42.49a8 8 0 0111.31 0L140 128.72a8 8 0 010 11.28 8 8 0 01-11.31 0l-11.31-11.31a8 8 0 01.03-11.28zM60 256a8 8 0 018-8h16a8 8 0 018 8 8 8 0 01-8 8H68a8 8 0 01-8-8zm40.15 73.21l-14.78 6.13A8 8 0 0174.92 331a8 8 0 014.33-10.46L94 314.43a8 8 0 0110.45 4.33 8 8 0 01-4.3 10.45zm4.33-136A8 8 0 0194 197.57l-14.78-6.12a8 8 0 01-4.3-10.45 8 8 0 0110.45-4.33l14.78 6.13a8 8 0 014.33 10.44zM197.57 418l-6.12 14.78a8 8 0 01-14.79-6.12l6.13-14.78a8 8 0 1114.78 6.12zM264 444a8 8 0 01-8 8 8 8 0 01-8-8v-16a8 8 0 018-8 8 8 0 018 8zm67-6.92a8 8 0 01-10.46-4.33L314.43 418a8 8 0 014.33-10.45 8 8 0 0110.45 4.33l6.13 14.78a8 8 0 01-4.34 10.42zm63.58-42.49a8 8 0 01-11.31 0L372 383.28a8 8 0 010-11.28 8 8 0 0111.31 0l11.31 11.31a8 8 0 01-.03 11.28zM286.25 286.25L110.34 401.66l115.41-175.91 175.91-115.41zM437.08 331a8 8 0 01-10.45 4.33l-14.78-6.13a8 8 0 01-4.33-10.45 8 8 0 0110.48-4.32l14.78 6.12a8 8 0 014.3 10.45zm6.92-67h-16a8 8 0 01-8-8 8 8 0 018-8h16a8 8 0 018 8 8 8 0 01-8 8z\"/>",
    "attrs": {
      "aria-hidden": "true",
      "data-prefix": "fab",
      "data-icon": "safari",
      "class": "svg-inline--fa fa-safari fa-w-16",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
});