define("ember-svg-jar/inlined/bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0)\" fill=\"#D9D9D9\"><path d=\"M0 42.843h154.477v.252H0zM0 30.494h154.477v.252H0zM0 18.145h154.477v.252H0zM0 5.796h154.477v.252H0zM0 55.191h154.477v.252H0z\"/></g><path d=\"M13.408 20.93c0-.556.451-1.008 1.008-1.008H28.82c.556 0 1.008.452 1.008 1.008v34.275H13.408V20.93zM85.663 9.157c0-.556.452-1.008 1.008-1.008h14.405c.557 0 1.008.452 1.008 1.008v46.12h-16.42V9.156zM121.789 16.516c0-.557.452-1.008 1.009-1.008h14.404c.557 0 1.008.451 1.008 1.008v38.81h-16.421v-38.81zM49.537 15.045c0-.557.451-1.008 1.008-1.008H64.95c.557 0 1.008.45 1.008 1.008v40.07H49.537v-40.07z\" fill=\"currentColor\"/><defs><clipPath id=\"clip0\"><path fill=\"#fff\" d=\"M0 0h154.358v63H0z\"/></clipPath></defs>",
    "attrs": {
      "viewBox": "0 0 155 63",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});