define("ember-svg-jar/inlined/five-stars", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\" stroke=\"#A6AFBF\" stroke-linecap=\"round\" stroke-width=\"3\"><path d=\"M25 34l-10.58 5.562 2.02-11.78-8.559-8.344 11.829-1.72L25 7l5.29 10.719 11.829 1.719-8.56 8.343 2.021 11.781zm46 0l-10.58 5.562 2.02-11.78-8.559-8.344 11.829-1.72L71 7l5.29 10.719 11.829 1.719-8.56 8.343 2.021 11.781zm46 0l-10.58 5.562 2.02-11.78-8.559-8.344 11.829-1.72L117 7l5.29 10.719 11.829 1.719-8.56 8.343 2.021 11.781zm46 0l-10.58 5.562 2.02-11.78-8.559-8.344 11.829-1.72L163 7l5.29 10.719 11.829 1.719-8.56 8.343 2.021 11.781zm46 0l-10.58 5.562 2.02-11.78-8.559-8.344 11.829-1.72L209 7l5.29 10.719 11.829 1.719-8.56 8.343 2.021 11.781z\" opacity=\".2\"/><path d=\"M21 31l-10.58 5.562 2.02-11.78-8.559-8.344 11.829-1.72L21 4l5.29 10.719 11.829 1.719-8.56 8.343 2.021 11.781zm46 0l-10.58 5.562 2.02-11.78-8.559-8.344 11.829-1.72L67 4l5.29 10.719 11.829 1.719-8.56 8.343 2.021 11.781zm46 0l-10.58 5.562 2.02-11.78-8.559-8.344 11.829-1.72L113 4l5.29 10.719 11.829 1.719-8.56 8.343 2.021 11.781zm46 0l-10.58 5.562 2.02-11.78-8.559-8.344 11.829-1.72L159 4l5.29 10.719 11.829 1.719-8.56 8.343 2.021 11.781zm46 0l-10.58 5.562 2.02-11.78-8.559-8.344 11.829-1.72L205 4l5.29 10.719 11.829 1.719-8.56 8.343 2.021 11.781z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "230",
      "height": "43",
      "viewBox": "0 0 230 43"
    }
  };
});