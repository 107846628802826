define("ember-svg-jar/inlined/small-business", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M64.5 64.5v-.75c0-3.45-2.7-6.3-6-6.6V54h-3v3.15c-3.3.45-6 3.15-6 6.6 0 3.45 2.7 6.3 6 6.6v7.5c-1.65-.3-3-1.8-3-3.6v-.75h-3v.75c0 3.45 2.7 6.3 6 6.6V84h3v-3.15c3.3-.45 6-3.15 6-6.6 0-3.45-2.7-6.3-6-6.6v-7.5c1.65.3 3 1.8 3 3.6v.75h3zm-12-.75c0-1.8 1.35-3.3 3-3.6v7.35c-1.65-.45-3-1.95-3-3.75zm9 10.5c0 1.8-1.35 3.3-3 3.6V70.5c1.65.45 3 1.95 3 3.75z\" fill=\"#5A5F68\"/><path d=\"M33 81H9c-1.65 0-3-1.35-3-3V31.95c1.35.6 2.85 1.05 4.5 1.05 3.75 0 7.2-2.1 9-5.1 1.8 3 5.25 5.1 9 5.1s7.2-2.1 9-5.1c1.8 3 5.25 5.1 9 5.1s7.2-2.1 9-5.1c1.8 3 5.25 5.1 9 5.1 1.65 0 3.15-.45 4.5-1.05v6.6l3 3V29.7c1.8-1.95 3-4.5 3-7.35V4.502c0-2.55-1.95-4.5-4.5-4.5h-66c-2.55 0-4.5 1.95-4.5 4.5v18c0 2.85 1.2 5.4 3 7.35v48.15c0 3.3 2.7 6 6 6h24V81zM57 3h13.5c.9 0 1.5.6 1.5 1.5v15H57V3zm15 19.5c0 4.2-3.3 7.5-7.5 7.5S57 26.7 57 22.5h15zM39 3h15v16.5H39V3zm15 19.5c0 4.2-3.3 7.5-7.5 7.5S39 26.7 39 22.5h15zM21 3h15v16.5H21V3zm15 19.5c0 4.2-3.3 7.5-7.5 7.5S21 26.7 21 22.5h15zM3 4.5C3 3.6 3.6 3 4.5 3H18v16.5H3v-15zm0 18h15c0 4.2-3.3 7.5-7.5 7.5S3 26.7 3 22.5z\" fill=\"#5A5F68\"/><path d=\"M76.648 50.55l-10.2-10.2c-.9-.9-1.95-1.35-3.15-1.35h-22.8c-2.55 0-4.5 1.95-4.5 4.5v42c0 2.55 1.95 4.5 4.5 4.5h33c2.55 0 4.5-1.95 4.5-4.5V53.7c0-1.2-.45-2.25-1.35-3.15zm-10.65-6.45l6.9 6.9h-5.4c-.9 0-1.5-.6-1.5-1.5v-5.4zm7.5 42.9h-33c-.9 0-1.5-.6-1.5-1.5v-42c0-.9.6-1.5 1.5-1.5h22.5v7.5c0 2.55 1.95 4.5 4.5 4.5h7.5v31.5c0 .9-.6 1.5-1.5 1.5z\" fill=\"#5A5F68\"/>",
    "attrs": {
      "width": "78",
      "height": "90",
      "viewBox": "0 0 78 90",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});