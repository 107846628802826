define("ember-svg-jar/inlined/logo-tmbc-standout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M4.335 20.526l26.072-14.14.842 31.003-25.88 14.21-1.034-31.073z\" fill=\"#fff\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M34.091 0l1.077 39.636L1.723 58 .4 18.271 34.091 0zM8.27 22.78l.746 22.416L27.329 35.14l-.608-22.368L8.27 22.78z\" fill=\"#fff\"/><path d=\"M30.65 37.04L4.933 21.283 30.02 7.537l.629 29.502z\" fill=\"#00599D\"/><path d=\"M4.933 21.277L30.65 37.036 5.555 50.779l-.622-29.502z\" fill=\"#73A5D8\"/>",
    "attrs": {
      "width": "36",
      "height": "58",
      "viewBox": "0 0 36 58",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});