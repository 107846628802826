define("ember-svg-jar/inlined/google", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M19.674 8.682h-9.318v3.863h5.364c-.5 2.455-2.591 3.864-5.364 3.864A5.897 5.897 0 014.447 10.5a5.897 5.897 0 015.91-5.91c1.408 0 2.68.5 3.68 1.32L16.948 3C15.174 1.455 12.902.5 10.357.5c-5.546 0-10 4.455-10 10s4.454 10 10 10c5 0 9.545-3.636 9.545-10 0-.59-.091-1.227-.228-1.818z\"/>",
    "attrs": {
      "viewBox": "0 0 23 23",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});