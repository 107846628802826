define("ember-svg-jar/inlined/company-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10.07 6.813c.346-.278.724-.52 1.126-.717H10.07v.717zm2.714-2.074v.838a6.074 6.074 0 011.325-.088V3.382H7.387V.7H.666v12.118h7.286a6.06 6.06 0 01-.14-1.297v-.06h-.425v-1.325h.585c.112-.477.28-.932.498-1.357H7.387V7.422h1.357V6.096H7.387V4.74h5.397zM6.03 3.382V2.057H4.705v1.325h1.326zm0 2.714V4.74H4.705v1.357h1.326zm0 2.683V7.422H4.705v1.357h1.326zm0 2.682v-1.325H4.705v1.325h1.326zM3.348 3.382V2.057H1.991v1.325h1.357zm0 2.714V4.74H1.991v1.357h1.357zm0 2.683V7.422H1.991v1.357h1.357zm0 2.682v-1.325H1.991v1.325h1.357zm13.663-.29c.028.079.041.159.041.238 0 .107-.013.2-.04.28 0 .08-.015.172-.042.278l1.03.838-.906 1.474-1.277-.398a3.57 3.57 0 01-.948.518l-.247 1.276H12.85l-.247-1.276a3.57 3.57 0 01-.947-.518l-1.277.398-.866-1.474.99-.838a1.134 1.134 0 01-.042-.279v-.518c0-.106.014-.2.041-.279l-.989-.877.866-1.475 1.277.439c.274-.24.59-.425.947-.558l.247-1.236h1.772l.247 1.236c.357.133.673.319.948.558l1.277-.439.906 1.475-1.03.877c.027.08.041.173.041.28zm-4.531 1.474c.357.319.782.478 1.277.478.467 0 .865-.159 1.194-.478a1.66 1.66 0 00.536-1.236c0-.451-.178-.837-.536-1.155a1.59 1.59 0 00-1.194-.519c-.495 0-.92.173-1.277.519a1.55 1.55 0 00-.495 1.155c0 .479.165.89.495 1.236z\"/>",
    "attrs": {
      "viewBox": "0 0 18 16",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});