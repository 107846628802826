window.EmberENV = (function(EmberENV, extra) {
  for (var key in extra) {
    EmberENV[key] = extra[key];
  }

  return EmberENV;
})(window.EmberENV || {}, {"FEATURES":{"ds-payload-type-hooks":true},"EXTEND_PROTOTYPES":{"Date":false},"ENABLE_DS_FILTER":true,"ember-oauth2":{"stripe":{"clientId":"ca_9k1SOcVUTXr9z8Z6eCs0tsiU7SiKy8HF","authBaseUri":"https://connect.stripe.com/oauth/authorize","scope":"read_write","responseType":"code"}},"_APPLICATION_TEMPLATE_WRAPPER":false,"_DEFAULT_ASYNC_OBSERVERS":true,"_JQUERY_INTEGRATION":false,"_TEMPLATE_ONLY_GLIMMER_COMPONENTS":true});

var runningTests = false;


