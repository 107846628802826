define("ember-svg-jar/inlined/logo-small", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M9.865 4L6.93 6.933h13.137v13.135L23 17.136V4zM4 9.865V23h13.135l2.933-2.932H6.931V6.933z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 27 27"
    }
  };
});