define("ember-svg-jar/inlined/experience-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.698 9.497c2.641 0 4.782-2.126 4.782-4.749C17.48 2.126 15.34 0 12.698 0c-2.64 0-4.781 2.126-4.781 4.748 0 2.623 2.14 4.749 4.781 4.749zM17.076 10.01a4.544 4.544 0 01-1.936 1.168c-2.712.778-5.808 0-6.58-1.168 0 0-4.26.779-3.872 9.614l4.542-1.13-1.329-1.291 3.136-.452 1.387-2.814 1.4 2.818 1.52.218 4.82-1.195s.396-3.845-3.088-5.768z\"/><path d=\"M21.987 11.56l.705 1.44 1.607.233-1.136 1.128.274 1.596-1.45-.778-1.45.778.274-1.596-1.176-1.128 1.607-.234.745-1.44zM2.312 11.56L3.057 13l1.607.233-1.176 1.128.274 1.596-1.45-.778-1.41.778.274-1.596L0 13.233l1.607-.234.705-1.44zM12.53 14.75l1.136 2.297 2.548.35-1.842 1.79.43 2.491-2.272-1.206-2.234 1.206.43-2.49-1.841-1.79 2.508-.351 1.137-2.296z\"/>",
    "attrs": {
      "viewBox": "0 0 25 22",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});