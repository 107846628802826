define("ember-svg-jar/inlined/office365", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11.51 2.976c.031.192.039 14.085.01 14.388l-7.085-1.688V5.883L.788 4.458c-.007.005-.014.007-.02.011-.006.004-.013.01-.013.016-.004.036-.009.073-.009.11v11.863c.054.067.124.075.185.098l10.41 3.896c.12.045.229.067.362.028 1.904-.553 3.81-1.099 5.714-1.647l.095-.031V2.122l-1.632-.454c-1.381-.38-2.764-.758-4.144-1.142a.59.59 0 00-.386.014C7.937 1.8 4.523 3.058 1.11 4.316a.463.463 0 00-.19.113l10.59-1.453z\"/>",
    "attrs": {
      "viewBox": "0 0 23 23",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});