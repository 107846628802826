define("ember-svg-jar/inlined/apple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M17.1 16.018c-.298.69-.652 1.326-1.061 1.91-.559.796-1.016 1.347-1.369 1.654-.546.502-1.131.76-1.758.774-.45 0-.993-.128-1.625-.388-.633-.258-1.216-.386-1.748-.386-.559 0-1.158.128-1.799.386-.641.26-1.158.395-1.554.409-.6.025-1.2-.24-1.798-.795-.382-.333-.86-.904-1.431-1.713-.614-.863-1.118-1.864-1.513-3.006-.424-1.233-.636-2.427-.636-3.582 0-1.324.286-2.466.86-3.423a5.04 5.04 0 011.799-1.82 4.84 4.84 0 012.432-.687c.478 0 1.104.148 1.882.438.776.291 1.274.439 1.492.439.164 0 .717-.173 1.655-.517.887-.32 1.636-.452 2.25-.4 1.662.135 2.91.79 3.741 1.97-1.487.901-2.222 2.163-2.207 3.781.013 1.26.47 2.31 1.37 3.143a4.5 4.5 0 001.368.897c-.11.319-.226.623-.35.916zM13.29 1.017c0 .988-.361 1.91-1.08 2.764-.87 1.016-1.92 1.602-3.058 1.51a3.076 3.076 0 01-.023-.375c0-.948.413-1.963 1.146-2.793.366-.42.832-.77 1.396-1.049.564-.274 1.097-.426 1.598-.452.014.132.02.264.02.395z\"/>",
    "attrs": {
      "viewBox": "0 0 23 23",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});