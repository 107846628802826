define("ember-yeti-table/components/yeti-table/pagination/component", ["exports", "@ember-decorators/component", "@ember/component", "@ember/object", "@ember/object/computed", "ember-yeti-table/components/yeti-table/pagination/template"], function (_exports, _component, _component2, _object, _computed, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
    Simple pagination controls component that is included to help you get started quickly.
    Yeti Table yields a lot of pagination data, so you shouldn't have a problem
    creating your own pagination controls.
  
    At any rate, this component tries to be as flexible as possible. Some arguments
    are provided to customize how this component behaves.
  
    If you want to render these controls on the table footer, you probably want
    a footer row that always spans all rows. To do that you can use a `colspan` equal
    to the yielded `visibleColumns.length` number. Example:
  
    ```hbs
    <YetiTable @data={{this.data}} @pagination={{true}} as |table|>
      ...
      <table.tfoot as |foot|>
        <foot.row as |row|>
          <row.cell colspan={{table.visibleColumns.length}}>
            <table.pagination/>
          </row.cell>
        </foot.row>
      </table.tfoot>
    </YetiTable>
    ```
  */
  let Pagination = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec3 = (0, _computed.or)('paginationData.isFirstPage', 'disabled'), _dec4 = (0, _computed.or)('paginationData.isLastPage', 'disabled'), _dec(_class = _dec2(_class = (_class2 = class Pagination extends _component2.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "theme", void 0);
      _defineProperty(this, "paginationData", void 0);
      _defineProperty(this, "paginationActions", void 0);
      _defineProperty(this, "disabled", void 0);
      _initializerDefineProperty(this, "shouldDisablePrevious", _descriptor, this);
      _initializerDefineProperty(this, "shouldDisableNext", _descriptor2, this);
      /**
       * Array of page sizes to populate the page size `<select>`.
       * Particularly useful with an array helper, e.g `@pageSizes={{array 10 12 23 50 100}}`
       * Defaults to `[10, 15, 20, 25]`.
       */
      _defineProperty(this, "pageSizes", [10, 15, 20, 25]);
      /**
       * Used to show/hide some textual information about the current page. Defaults to `true`.
       */
      _defineProperty(this, "showInfo", true);
      /**
       * Used to show/hide the page size selector. Defaults to `true`.
       */
      _defineProperty(this, "showPageSizeSelector", true);
      /**
       * Used to show/hide the previous and next page buttons. Defaults to `true`.
       */
      _defineProperty(this, "showButtons", true);
    }
    changePageSize(ev) {
      this.paginationActions.changePageSize(ev.target.value);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "shouldDisablePrevious", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "shouldDisableNext", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "changePageSize", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "changePageSize"), _class2.prototype)), _class2)) || _class) || _class);
  var _default = _exports.default = Pagination;
});