define("ember-tracked-local-storage/index", ["exports", "ember-tracked-local-storage/utils/tracked-in-local-storage"], function (_exports, _trackedInLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "localStorageGet", {
    enumerable: true,
    get: function () {
      return _trackedInLocalStorage.localStorageGet;
    }
  });
  Object.defineProperty(_exports, "localStorageSet", {
    enumerable: true,
    get: function () {
      return _trackedInLocalStorage.localStorageSet;
    }
  });
  Object.defineProperty(_exports, "trackedInLocalStorage", {
    enumerable: true,
    get: function () {
      return _trackedInLocalStorage.trackedInLocalStorage;
    }
  });
});