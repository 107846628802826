define("ember-svg-jar/inlined/startup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M83 29c-.03 0-.058.004-.088.004C82.164 22.264 76.439 17 69.5 17c-2.409 0-4.747.647-6.798 1.844C58.127 6.52 48.26.979 47.717.683l-.716-.391-.719.391c-.384.209-5.446 3.053-9.994 9.066a10.452 10.452 0 00-5.787-1.75c-1.745 0-3.424.443-4.939 1.253A10.443 10.443 0 0018.5 6.5c-5.32 0-9.718 3.98-10.398 9.12C3.802 16.295.5 20.013.5 24.5c0 4.963 4.037 9 9 9h19.526c.087 6.478.706 12.329 1.533 17.32L17.001 64.38V74.42L36.682 69.5h1.548l1.5 9h14.54l1.5-9h1.548L77 74.42V64.38L63.442 50.821c.045-.276.095-.54.139-.821H83c5.788 0 10.5-4.71 10.5-10.5S88.789 29 83 29H83zM46.993 3.752C49.019 5.062 54.9 9.39 58.67 17.178A17.887 17.887 0 0147 21.5c-4.335 0-8.445-1.55-11.707-4.353 3.751-7.812 9.63-12.08 11.7-13.395zM3.5 24.5c0-3.31 2.691-6 6-6s6 2.69 6 6h3c0-4.404-3.18-8.071-7.363-8.844.636-3.494 3.688-6.156 7.363-6.156 4.136 0 7.5 3.364 7.5 7.5h3c0-1.957-.547-3.784-1.483-5.353 2.268-.956 4.976-.754 7.063.571-2.901 4.581-5.267 10.619-5.542 18.282H9.5c-3.31 0-6-2.691-6-6zM20 65.62l11.21-11.209c.465 2.343.954 4.443 1.428 6.258l-5.502 1.376.726 2.908 5.577-1.395c.418 1.42.79 2.56 1.078 3.39L20 70.578v-4.957zm31.73 9.88h-9.461l-1-6h11.46l-1 6zm-14.184-9C36.28 62.92 32 49.506 32 32c0-4.582.818-8.549 2.066-11.96A20.876 20.876 0 0047 24.5a20.88 20.88 0 0012.904-4.44C61.169 23.477 62 27.437 62 32c0 17.507-4.281 30.92-5.546 34.5H37.546zM74 65.62v4.959L59.48 66.95c.288-.83.66-1.97 1.078-3.39l5.577 1.395.726-2.908-5.502-1.376c.474-1.815.963-3.915 1.428-6.258L74 65.62zM83 47H64.011c.59-4.494.989-9.533.989-15 0-3.776-.512-7.167-1.349-10.209a10.479 10.479 0 015.849-1.79c5.436 0 9.92 4.154 10.447 9.455-4.305 1.311-7.448 5.316-7.448 10.044h3c0-4.135 3.365-7.5 7.5-7.5 4.136 0 7.5 3.365 7.5 7.5 0 4.136-3.364 7.5-7.5 7.5H83z\" fill=\"#5A5F68\"/>",
    "attrs": {
      "width": "94",
      "height": "79",
      "viewBox": "0 0 94 79",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});