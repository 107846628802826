define("ember-svg-jar/inlined/retail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0_11574_304101)\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M76.47 66.661h2.353c1.57 0 1.57 2.339 0 2.339H1.176c-1.568 0-1.568-2.339 0-2.339H3.53V17.137a10.44 10.44 0 01-2.353-6.612V1.17C1.176.524 1.703 0 2.353 0h75.294c.65 0 1.176.524 1.176 1.17v9.355a10.44 10.44 0 01-2.352 6.612v49.524zm-8.235 0h5.883V19.275a10.578 10.578 0 01-5.883 1.776c-4.095 0-7.65-2.318-9.411-5.703a10.602 10.602 0 01-9.412 5.703A10.6 10.6 0 0140 15.348a10.602 10.602 0 01-9.412 5.703c-4.095 0-7.65-2.318-9.412-5.703a10.602 10.602 0 01-9.411 5.703c-2.176 0-4.2-.654-5.883-1.775V66.66h42.353V31.576c0-.646.527-1.17 1.177-1.17h17.647c.65 0 1.176.524 1.176 1.17v35.085zM50.588 32.746V66.66h15.294V32.746H50.588zM22.353 2.339v8.186c0 4.515 3.69 8.187 8.235 8.187 4.542 0 8.236-3.67 8.236-8.187V2.34H22.353zm37.647 0v8.186c0 4.515 3.69 8.187 8.235 8.187 4.542 0 8.236-3.67 8.236-8.187V2.34H60zm-18.824 0v8.186c0 4.515 3.691 8.187 8.236 8.187 4.541 0 8.235-3.67 8.235-8.187V2.34h-16.47zm-37.647 0v8.186c0 4.515 3.691 8.187 8.236 8.187 4.541 0 8.235-3.67 8.235-8.187V2.34H3.53zm9.412 53.797c-.65 0-1.176-.524-1.176-1.17v-23.39c0-.646.526-1.17 1.176-1.17h28.235c.65 0 1.177.524 1.177 1.17v23.39c0 .646-.527 1.17-1.177 1.17H12.941zm1.177-2.34H40v-21.05H14.118v21.05z\" fill=\"#74747E\" stroke=\"#74747E\"/></g><defs><clipPath id=\"clip0_11574_304101\"><path fill=\"#fff\" d=\"M0 0h80v69H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "80",
      "height": "69",
      "viewBox": "0 0 80 69",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});