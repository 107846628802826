define("ember-svg-jar/inlined/hours", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path opacity=\".05\" d=\"M37.001 51.705c14.207 0 25.724-11.535 25.724-25.764C62.725 11.71 51.208.175 37.001.175S11.277 11.71 11.277 25.94c0 14.229 11.517 25.764 25.724 25.764z\" fill=\"currentColor\"/><path d=\"M54.83 14.117c-7.99.715-27.785 2.594-35.74 3.353a3.742 3.742 0 00-2.402 1.202 3.753 3.753 0 00-.981 2.504v30.882a3.742 3.742 0 003.4 3.732l35.124 3.274a3.74 3.74 0 003.755-2.168c.216-.469.332-.977.342-1.493.176-9.106.546-31.077.237-38.824-.352-1.006-1.303-2.082-3.735-2.462z\" fill=\"url(#paint0_linear_514_2850)\"/><path d=\"M37 48.44c6.81 0 12.333-5.53 12.333-12.353 0-6.822-5.522-12.353-12.334-12.353-6.811 0-12.333 5.531-12.333 12.353 0 6.823 5.522 12.353 12.333 12.353z\" stroke=\"#fff\" stroke-width=\"2.9\" stroke-miterlimit=\"10\"/><path d=\"M36.47 28.676v8.912h8.898\" stroke=\"#fff\" stroke-width=\"2.9\" stroke-miterlimit=\"10\"/><defs><linearGradient id=\"paint0_linear_514_2850\" x1=\"18.676\" y1=\"56.558\" x2=\"58.911\" y2=\"16.475\" gradientUnits=\"userSpaceOnUse\"><stop offset=\".1\" stop-color=\"currentColor\" class=\"cl-text-primary\"/><stop offset=\"1\" stop-color=\"currentColor\" class=\"cl-text-primary-shade1\"/></linearGradient></defs>",
    "attrs": {
      "width": "74",
      "height": "60",
      "viewBox": "0 0 74 60",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});