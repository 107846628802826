define("ember-svg-jar/inlined/communication", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.865 6.5v11.762c0 2.26 1.84 4.1 4.1 4.1h.876v.31c0 2.26 1.84 4.1 4.1 4.1h.701l.074.081.068-.081h6.807a118.86 118.86 0 013.414 3.86l.532.632 4.043-4.492h1.709c2.26 0 4.1-1.84 4.1-4.1V10.91c0-2.261-1.84-4.1-4.1-4.1h-.876V6.5c0-2.261-1.84-4.1-4.1-4.1H14.964c-2.26 0-4.1 1.839-4.1 4.1l.001-.001zM37.29 8.248a2.666 2.666 0 012.663 2.663v11.761a2.666 2.666 0 01-2.663 2.663H34.94l-3.37 3.745a110.595 110.595 0 00-3.144-3.52l-.213-.225h-8.271a2.666 2.666 0 01-2.663-2.663V10.911a2.666 2.666 0 012.663-2.663h17.347zM12.303 6.5a2.665 2.665 0 012.662-2.663h17.348A2.665 2.665 0 0134.975 6.5v.31H19.94c-2.26 0-4.1 1.84-4.1 4.1v10.013h-.876a2.666 2.666 0 01-2.662-2.662V6.5zM8.758 31.685a3.864 3.864 0 103.863 3.864c0-1.387-.734-2.6-1.832-3.281a3.829 3.829 0 00-2.031-.583zm2.905 3.863a2.908 2.908 0 01-2.905 2.905 2.908 2.908 0 01-2.905-2.905 2.908 2.908 0 012.905-2.905 2.908 2.908 0 012.905 2.905zM2.757 45.6h.958a3.55 3.55 0 013.545-3.545h2.996a3.55 3.55 0 013.545 3.545h.959a4.509 4.509 0 00-4.504-4.504H7.26A4.51 4.51 0 002.756 45.6zM24 39.412a3.864 3.864 0 003.863-3.864A3.863 3.863 0 1024 39.412zM30.002 45.6a4.509 4.509 0 00-4.504-4.504h-2.996A4.509 4.509 0 0018 45.6h12.003zM37.21 32.267a3.857 3.857 0 00-1.663 4.41 3.865 3.865 0 007.558-1.129 3.864 3.864 0 00-3.864-3.863c-.746 0-1.44.216-2.031.582zm4.937 3.281a2.908 2.908 0 01-2.905 2.905 2.908 2.908 0 01-2.905-2.905 2.908 2.908 0 012.905-2.904 2.908 2.908 0 012.905 2.904zM40.74 41.096h-2.996A4.509 4.509 0 0033.24 45.6h.959a3.55 3.55 0 013.545-3.545h2.996a3.55 3.55 0 013.545 3.545h.959a4.509 4.509 0 00-4.504-4.504z\" fill=\"#5A5F68\"/>",
    "attrs": {
      "width": "48",
      "height": "48",
      "viewBox": "0 0 48 48",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});