define("ember-svg-jar/inlined/quick-setup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M19.641 12.986l2.163 1.643c.224.157.256.376.096.657l-2.067 3.475c-.128.219-.336.281-.624.187l-2.547-.986c-.673.47-1.25.799-1.73.986l-.385 2.63c-.064.281-.224.422-.48.422H9.933c-.257 0-.417-.14-.481-.422l-.385-2.63a7.913 7.913 0 01-1.73-.986l-2.547.986c-.288.094-.496.032-.624-.187L2.1 15.286c-.16-.281-.128-.5.096-.657l2.163-1.643A7.412 7.412 0 014.31 12c0-.438.016-.767.048-.986L2.196 9.371c-.224-.157-.256-.376-.096-.657l2.067-3.475c.128-.219.336-.281.624-.187l2.547.986c.673-.47 1.25-.799 1.73-.986l.385-2.63c.064-.281.224-.422.48-.422h4.133c.257 0 .417.14.481.423l.384 2.629c.61.25 1.186.579 1.73.986l2.548-.986c.288-.094.496-.032.624.187L21.9 8.714c.16.281.128.5-.096.657l-2.163 1.643c.032.22.048.548.048.986 0 .438-.016.767-.048.986zM17 12a5 5 0 11-10 0 5 5 0 0110 0zm-6.064 3.911c.07.06.16.089.271.089a.48.48 0 00.165-.033c.06-.023.1-.052.12-.09l3.428-4.433c.08-.103.1-.185.06-.244-.03-.06-.12-.089-.27-.089h-2.105l.541-2.811c.02-.081-.005-.152-.075-.211A.405.405 0 0012.8 8a.48.48 0 00-.166.033c-.06.023-.1.052-.12.09l-3.428 4.433c-.08.103-.105.185-.075.244.04.06.136.089.286.089h2.104l-.54 2.811c-.02.082.004.152.074.211z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});